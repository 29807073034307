import React from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import './index.css'

export const ActionButton = ({children, onClick, width, btnStyle }) => {

    const buttonClass = btnStyle === 'btn_style_1' ? 'export-button' : 'import-button'

    return(<React.Fragment>
        <Button className={`action-button ${buttonClass}`} id={`action-button ${buttonClass}`} onClick={onClick} style={{ width: `${width? width : '' }` }}>
            {children}
        </Button>
    </React.Fragment>    
    )
}

ActionButton.propTypes ={
    children: PropTypes.node,
    onClick: PropTypes.func,
    width: PropTypes.string,
    btnStyle: PropTypes.string
}


export const ActionButtonUpload = ({children, onChange}) =>{

    return(<React.Fragment>
            <Button
                className={`action-button-upload`} id={`action-button-upload`}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}      
                onChange={(event)=>onChange(event)}          
            >
                {children}
                <VisuallyHiddenInput type="file"/>
            </Button>
    </React.Fragment>
    )
}

ActionButtonUpload.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
    
});

