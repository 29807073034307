
import React, { useContext } from 'react'
import './index.css'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext'
import { AuthContext } from '../../../contexts/LegalInsights/auth'
import { useNavigate } from 'react-router-dom'

const MenuConfiguracoes = () => {
    const { showMeuPerfil, setShowMeuPerfil, showMenuConfiguracoes, setShowMenuConfiguracoes, } = useContext(LegalInsightsCrudContext) 
    const { signOut } = useContext(AuthContext)   
    const navegate = useNavigate() 

    const handleOnClick = (event) => {
        const label =  event.target.title
        switch(label){
            case 'conta':
                setShowMeuPerfil(!showMeuPerfil)
                setShowMenuConfiguracoes(!showMenuConfiguracoes)
                return

            case 'modulos':
                setShowMeuPerfil(false)
                setShowMenuConfiguracoes(false)
                navegate('/modulos')
                return
            case 'sair':
                setShowMeuPerfil(false)
                setShowMenuConfiguracoes(false)
                signOut()
                return
            
            default:
                setShowMeuPerfil(false)
                setShowMenuConfiguracoes(false)
                navegate('/modulos')
                return
        }
    }


    return(<div className="menu-configuracoes-Container">
        <ul>
        <li title='modulos' value={'modulos'} onClick={(event)=> handleOnClick(event)}>Home</li>
            <li title='conta' value={'conta'} onClick={(event)=> handleOnClick(event)}>Conta</li>            
            <li title='sair' value={'sair'} onClick={(event)=> handleOnClick(event)}>Sair</li>
        </ul>
    </div>)
}

export default MenuConfiguracoes