import React, { useContext, useEffect, useState } from 'react'

// import { DataTable } from 'primereact/datatable'
// import { Column } from 'primereact/column'
import { readExcelFile } from '../../utils'
import Header from '../../Components/Commons/Header'
import MenuLatel from '../../Components/Commons/MenuLateral'
import { menuLateralOpcoesPorModulos } from '../../utils/Compartilhados'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'

import { Toolbar } from 'primereact/toolbar'
import { filtroUsuario, tipoUsuario } from '../../utils/data'
import { Dropdown } from 'primereact/dropdown'
import { AuthContext } from '../../contexts/LegalInsights/auth'
import { Dialog } from 'primereact/dialog'
import FileUpload from '../../Components/Commons/Upload'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox';
import './index.css'
import { ButtonLabelComponent } from '../../Components/Commons/Button'
import useForm from '../../hooks/useForm'
import { showToast } from '../../utils/Compartilhados/CustomToast'

import file from '../../assets/Arquivos/Modelo - Controle de acesso.xlsx'
import DataGridCustom from '../../Components/Commons/DataGrid/DataGridCustom'
import { Select, MenuItem, styled, Box } from '@mui/material';
import ReactLoading from 'react-loading'

const Usuario = () => {
    const { showMenuConfiguracoes, showMeuPerfil, criarUsuario, consultarProjetosRLANET, projetosRLANET, arquivoUpload, criarUsuarioEmLote ,
      columnVisibilityModel, alterColumnVisibility, moduloSelecionado
    } = useContext(LegalInsightsCrudContext) 
    const { user, getAllUsers, usuarios , ativarUser, removeUser, load, alterarPerfil } = useContext(AuthContext)

    const [showModalNovoUsuario, setShowModalNovoUsuario] = useState(false)
    const [showModalCadastroEmLote, setShowModalCadastroEmLote] = useState(false)
    const [showModalCadastroUnico, setshowModalCadastroUnico] = useState(false)
    const [_usuarios, _setUsuarios] = useState([])
    const [itemSelecionado, setItemSelecionado] = useState()
    const [_projetosRLANET, _setProjetoRLANET] = useState([])

    const [usuarioAtivo, setUsuarioAtivo ] = useState(1)
    const [form, handleInputChange, clear] = useForm({
        nomeCompleto: '',
        email: '',
        telefone: '',
        celular: '',
        cnpj: '',
        nomeCliente: '',
        projeto: [],
        tipoUsuario: '',
        acessoModuloSeguros: false,
        acessoModuloProjetoAcordo: false,
        usuarioAprovador: false,
        acessoModuloGestaoDados: false,
        acessoModuloDepositoJudiciais:false,
        status: 1
      }
    )

    useEffect(()=>{
      getAllUsers('Ativo')
      consultarProjetosRLANET()
      alterColumnVisibility({id: false })
    },[])

    useEffect(()=>{
      _setUsuarios(usuarios)
    },[usuarios])

    useEffect(()=>{
      getAllUsers(form.status === 1? "Ativo" : "Inativo")
      setUsuarioAtivo(form.status === 1? 1 : 0)
    },[form.status])

    useEffect(()=>{
      _setProjetoRLANET(projetosRLANET)
    },[projetosRLANET])

    const rightToolbarTemplate = () => {
        return (
          <React.Fragment>          
            <ButtonLabelComponent label={'Novo'} icon="pi pi-plus" onClick={onClickToobarRight} />                                    
            {usuarioAtivo === 1 && <ButtonLabelComponent label="Inativar" icon="pi pi-trash" style={{border:'none'}}  onClick={() => ativarOuInativarUsuario("Inativar")} info={'info'}/>}
            {usuarioAtivo === 0 && <ButtonLabelComponent label="Ativar" icon="pi pi-check"  onClick={() => ativarOuInativarUsuario("Ativar")} info={'alert'} />}
          </React.Fragment>
        )
    }

    const ativarOuInativarUsuario = async (statusUsuario) => {
        const _ids = []
        if(statusUsuario === 'Ativar'){          
          if(itemSelecionado?.length > 0){
            itemSelecionado.map(item => _ids.push(item.uid))
            await ativarUser(_ids)
            setItemSelecionado()
          }else{
            return showToast('info','É necessário selecionar um usuário!')
          }
        }

        if(statusUsuario === 'Inativar'){
          if(itemSelecionado?.length > 0){
            itemSelecionado.map(item => _ids.push(item.uid))
            await removeUser(_ids)
            setItemSelecionado()
          }else{
            return showToast('info','É necessário selecionar um usuário!')
          }          
        }
    }

    const leftToolbarTemplate = () => {
        return (
          <React.Fragment>
            <span className='page-title'>Filtro Status: </span>
            <Dropdown value={form.status} options={filtroUsuario} name='status' placeholder="Status" onChange={handleInputChange} id={form.status}/>            
          </React.Fragment>
        )
    }

    const onHideNovoUsuario = () => {
      setShowModalNovoUsuario(!showModalNovoUsuario)
    }

    const onClickToobarRight = () => {
      setShowModalNovoUsuario(!showModalNovoUsuario)
    }

    const onClickNovoUsuarioEmLote = () => {
      setShowModalCadastroEmLote(!showModalCadastroEmLote)
    }

    const onClickNovoUsuarioUnico = () => {
      setshowModalCadastroUnico(!showModalCadastroUnico)
    }

    const onHideNovoUsuarioEmLote = () => {
      setShowModalCadastroEmLote(!showModalCadastroEmLote)
    }

    const onHideNovoUsuarioUnico = () => {
      setshowModalCadastroUnico(!showModalCadastroUnico)
    }


    const showModalCadastroNovoUsuario = () => {
        return(<Dialog
          visible={showModalNovoUsuario}
          style={{ width: '450px' }}
          header="Cadastro de Usuário"
          modal
          className='show-modal-cadastro-novo-usuario'
          onHide={onHideNovoUsuario}
          footer={
            <React.Fragment>
              <div className="dialog-show-modal-cadastro-novo-usuario footer">
                <ButtonLabelComponent label={'Em lote'} onClick={onClickNovoUsuarioEmLote} />
                <ButtonLabelComponent label={`Unitário`} onClick={onClickNovoUsuarioUnico} /> 
              </div>  
            </React.Fragment>                  
          }
        >
            <div className="dialog-show-modal-Cadastro-novo-usuario p-dialog-content">
              Como deseja realizar o cadastro?
            </div>          
        </Dialog>)
    }

    const salvarNovoUsuario = async () => {
      const response = await criarUsuario(form)
      
      if(response?.status === 200){
        clear()
        onHideNovoUsuarioUnico()
        onHideNovoUsuario()
      }
    }

    const lerArquivoExcel = async () => {
        if(arquivoUpload){
          const json = await readExcelFile(arquivoUpload, "")
          const _usuarios = []
          if(json.length > 0){
            json.map( item => {
                _usuarios.push({
                  userType: item["Tipo de Usuário"],
                  nomeCliente: item["Nome do Cliente"],
                  cnpj: item["CNPJ"],
                  name: item["Nome Completo do Usuário"],
                  email: item["E-mail do Usuário"],
                  observacoes: item["Observações"]? item["Observações"] : "",
                  avatarUrl: "",
                  perfil:{
                      acessoMenuStatusReports: String(item["Status Report na Legal Insights"]).toLocaleLowerCase() === "sim"? true : false,
                      usuarioAprovador: String(item["Perfil aprovador?"]).toLocaleLowerCase() === "sim"? true : false,
                      acessoModuloSeguros: String(item["Seguros"]).toLocaleLowerCase() === "sim"? true : false,
                      acessoModuloProjetoAcordo: String(item["Projeto Acordos"]).toLocaleLowerCase() === "sim"? true : false,
                      acessoModuloPainelGestao: String(item["Painéis de Gestão Automatizados"]).toLocaleLowerCase() === "sim"? true : false,
                  },
                  grupo: item["Projeto (grupo)"]? item["Projeto (grupo)"] : "(SEM)"
              })
            })

           await criarUsuarioEmLote(_usuarios)
            

           onHideNovoUsuarioEmLote()
           onHideNovoUsuario()
           
          }
        }
    }


    const showModalCadastroNovoUsuarioEmLote = () => {
      return(
        <Dialog
          visible={showModalNovoUsuario}
          style={{ width: '450px' }}
          header="Cadastro de Usuário em lote"
          modal
          className='show-modal-cadastro-novo-usuario-em-lote'
          onHide={onHideNovoUsuarioEmLote}
          footer={            
            <React.Fragment>
              <div className="dialog-show-modal-cadastro-novo-usuario-em-lote footer">
                <ButtonLabelComponent label="Processar" icon="pi pi-cloud-upload" onClick={lerArquivoExcel} /> 
                <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideNovoUsuarioEmLote} info={'info'} />
              </div>  
            </React.Fragment>   
          }
        >
          <div className='dialog-show-modal-cadastro-novo-usuario-em-lote conteudo'>
            <a href={`${file}`} target='_blank' rel="noreferrer" download>Baixar arquivo modelo</a>

              <FileUpload  textoDoUpload={<>
                <span>Arraste</span> o arquivo aqui até <br/>
                o retângulo ou<br/>
                <span>clique aqui e escolha</span> o arquivo <br />
                para fazer upload
                </>}
              /> 
          </div>              
        </Dialog>
      )
    }

    const showModalCadastroNovoUsuarioUnico = () => {
      return(<Dialog
        visible={showModalCadastroUnico}
        style={{ width: '450px' }}
        header="Cadastro de usuário unitário"
        modal
        className='show-modal-cadastro-novo-usuario'
        onHide={onHideNovoUsuarioUnico}
        footer={            
          <React.Fragment>
            <div className="dialog-show-modal-cadastro-novo-usuario-unico footer">
              <ButtonLabelComponent label="Salvar" icon="pi pi-save" onClick={() => salvarNovoUsuario()} /> 
              <ButtonLabelComponent label="Cancelar" icon="pi pi-times-circle" onClick={onHideNovoUsuarioUnico} info={'info'} /> 
            </div>  
          </React.Fragment>  
        }
      >
          <div className='dialog-show-modal-cadastro-novo-usuario-unico form'>
            <InputText className='input-textLabel' name='nomeCompleto' placeholder='Nome Completo' value={form.nomeCompleto} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='email' placeholder='E-mail' value={form.email} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='telefone' placeholder='Telefone' value={form.telefone} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='celular' placeholder='Celular' value={form.celular} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='cnpj' placeholder='CNPJ' value={form.cnpj} onChange={handleInputChange} />
            <InputText className='input-textLabel' name='nomeCliente' placeholder='Nome do cliente' value={form.nomeCliente} onChange={handleInputChange} />
            <Dropdown className='perfil-tipoUsuario' name='projeto' placeholder="Projeto" value={form.projeto} options={_projetosRLANET} onChange={handleInputChange} /> 
            <Dropdown className='perfil-tipoUsuario' name='tipoUsuario' placeholder="Tipo de usuário" value={form.tipoUsuario} options={tipoUsuario} onChange={handleInputChange} /> 
          </div>          
          
          <div className="dialog-show-modal-cadastro-novo-usuario-unico form-checkbox flex align-items-center">            
            <Checkbox inputId="ingredient1" name="acessoModuloSeguros" value={form.acessoModuloSeguros} onChange={handleInputChange} checked={form.acessoModuloSeguros} />            
            <label htmlFor="ingredient1" className="ml-2">Seguros</label>

            <Checkbox inputId="ingredient1" name="acessoModuloGestaoDados" value={form.acessoModuloGestaoDados} onChange={handleInputChange} checked={form.acessoModuloGestaoDados} />            
            <label htmlFor="ingredient1" className="ml-2">Gestão de Dados</label>

          </div>

          <div className="dialog-show-modal-cadastro-novo-usuario-unico form-checkbox flex align-items-center">  
            <Checkbox inputId="ingredient1" name="acessoModuloProjetoAcordo" value={form.acessoModuloProjetoAcordo} onChange={handleInputChange} checked={form.acessoModuloProjetoAcordo} />            
            <label htmlFor="ingredient1" className="ml-2">Projeto Acordos</label>

            <Checkbox inputId="ingredient1" name="usuarioAprovador" value={form.usuarioAprovador} onChange={handleInputChange} checked={form.usuarioAprovador} />            
            <label htmlFor="ingredient1" className="ml-2">Usuário Aprovador?</label>
          </div>

          <div className="dialog-show-modal-cadastro-novo-usuario-unico form-checkbox flex align-items-center">
            <Checkbox inputId="ingredient1" name="acessoModuloDepositoJudiciais" value={form.acessoModuloDepositoJudiciais} onChange={handleInputChange} checked={form.acessoModuloDepositoJudiciais} />            
            <label htmlFor="ingredient1" className="ml-2">Depositos Judiciais</label>
          </div>
      </Dialog>)
    }

    const columns = [
      {
          field: "id",
          headerName: "id",
          hide: columnVisibilityModel?.id,
          width: 250,
      },
      {
          field: "name",
          headerName: "Nome Completo",
          width: 250,
          editable: true,
          renderEditCell: (params)=> <EditTexto {...params} />
      },
      {
          field: "email",
          headerName: "E-mail",
          width: 250,
          editable: false,
      },
  
      {
          field: "telefone",
          headerName: "Telefone",
          width: 250,
          editable: true,
          renderEditCell: (params)=> <EditTexto {...params} />
          
      },
      {
        field: "celular",
        headerName: "Celular",
        width: 250,
        editable: true,        
        renderEditCell: (params)=> <EditTexto {...params} />
    },

      {
        field: "cnpj",
        headerName: "CNPJ",
        width: 250,
        editable: true,        
        renderEditCell: (params)=> <EditTexto {...params} />
    },        
      
    {
      field: "nomeCliente",
      headerName: "Nome do cliente",
      width: 250,
      editable: true,
      renderEditCell: (params)=> <EditTexto {...params} />
    },

    {
      field: "grupo",
      headerName: "Projeto",
      width: 250,
      editable: false,
    },
    {
      field: "tipoUsuario",
      headerName: "Tipo de Usuário",
      width: 250,
      editable: false,
    },
    {
      field: "perfil.aprovador",
      headerName: "Usuário Aprovador?",
      width: 250,
      editable: true,
      renderCell: (event) => handleSimNao(event,'aprovador'),
      renderEditCell: (params) => <SimNaoEditComponent {...params} />,
    },
    {
      field: "perfil.acessoModuloSeguros",
      headerName: "Acessa Módulo de Seguros?",
      width: 250,
      editable: true,
      renderCell: (event) => handleSimNao(event,'acessoModuloSeguros'),
      renderEditCell: (params) => <SimNaoEditComponent {...params} />,
    },
    {
      field: "perfil.acessoModuloProjetoAcordo",
      headerName: "Acessa Módulo Projeto Acordo?",
      width: 250,
      editable: true,
      renderCell: (event) => handleSimNao(event,'acessoModuloProjetoAcordo'),
      renderEditCell: (params) => <SimNaoEditComponent {...params} />,
    },
    {
      field: "perfil.acessoModuloPainelGestao",
      headerName: "Acessa Módulo Gestão de dados?",
      width: 250,
      editable: true,
      renderCell: (event) => handleSimNao(event,'acessoModuloPainelGestao'),
      renderEditCell: (params) => <SimNaoEditComponent {...params} />,
    }
    ]

    const handleSimNao = (event, nomePropriedade) => {
      const aprovador = event.row.perfil[nomePropriedade]
      return(<span>{aprovador}</span>)
    }

    const EditTexto = (params) => {
      const [value, setValue] = React.useState(params.value);
    
      const handleChange = (event) => {
        setValue(event.target.value);
      };
    
      const handleBlur = async (event) => {
        const newValue = event.target.value;
        params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue }, event);
        const paramsData = { id: params.id, field: params.field, value: newValue };
        await alterarPerfil(paramsData);
      };
    
      return (
        <input
          type="text"
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ width: '100%', height: '100%', border: 'none' }}
        />
      );
    };
    
    

    const SimNaoEditComponent = (params) => {
      const perfil = params.row["perfil"]
      const [value, setValue] = React.useState(perfil[params.field.split('.')[1]]);
    
      const handleChange = async (event) => {
        setValue(event.target.value);
        params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value }, event);
        const paramsData = { id: params.id, field: params.field, value: event.target.value }
        await alterarPerfil(paramsData);
      };
    
      return (
        <CustomSelect value={value} onChange={handleChange} autoWidth>
          <CustomMenuItem value="Sim">Sim</CustomMenuItem>
          <CustomMenuItem value="Não">Não</CustomMenuItem>
        </CustomSelect>
      );
    };

    const CustomSelect = styled(Select)({
      border: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '& .MuiSelect-select': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    });
    
    const CustomMenuItem = styled(MenuItem)({
      border: 'none',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    });


    return(
      <div className="modulo-projeto-acordos-usuario">
        <Header /> 
          <div className="projeto-acordo-container">
            <div className="projeto-acordo-menu-left">
              <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado, user?.perfil)}/>
            </div>
                <div className="projeto-acordo-conteudo">
                  <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >

                      <Toolbar className="mb-4" left={<span className='page-title'>Cadastro de Usuário</span>} right={rightToolbarTemplate}></Toolbar>

                      <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                      <div className="data-grid-meus-processos">
                        {load? 
                            <div className="loadProcessos">
                                <ReactLoading type='spin' color='#4e1975' />
                            </div> 
                        :
                            <Box sx={{ height: '72vh', width: 'auto' }}>
                                <DataGridCustom                                             
                                    column={columns} 
                                    rows={_usuarios} 
                                    selection={false}
                                />
                            </Box>
                        }
                      </div>
                  </div>

                  {showModalNovoUsuario && showModalCadastroNovoUsuario()} 

                  {showModalCadastroEmLote && showModalCadastroNovoUsuarioEmLote()}

                  {showModalCadastroUnico && showModalCadastroNovoUsuarioUnico()}
                </div>
          </div>
      </div>
    )
}

export default Usuario
