import React, { useContext, useEffect } from 'react'
import './index.css'
import CardEtapas from '../../Components/LegalInsights/CardEtapas'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'
import { InputText } from 'primereact/inputtext'
import useForm from '../../hooks/useForm'
import IMGEsqueceuSenha from '../../assets/Imagens/3.png'
import { ButtonLabelComponent } from '../../Components/Commons/Button'
import { showToast } from '../../utils/Compartilhados/CustomToast'
import { isStrongPassword } from '../../utils/Compartilhados/Validacoes'
import { useNavigate } from 'react-router-dom'

const EsqueceuSenha = () => {    
    const [form, handleInputChange] = useForm({ 
        email: '', 
        codigo: '',
        novaSenha: '',
        confirmarSenha: ''
    }) 

    const { stepEtapa, 
            setStepEtapa, 
            enviarCodigoSeguranca, 
            validarCodigoSeguranca, 
            alterPasswordLegalInsights,
            usuarioExistente
    } = useContext(LegalInsightsCrudContext)
    
    const navegate = useNavigate()
    let headerTitulo = 'ESQUECI A SENHA'

    useEffect(()=>{
        setStepEtapa(1)
    },[])

    const onClickEtapa = async (step) => {
        
        try {
           
            if(step === 2){
                if(form.email === '' || form.email === undefined) return showToast('warning','E-Mail não informado!')
    
                const _usuarioExistente = await usuarioExistente(form.email)
                if(_usuarioExistente.message){
                    const retorno = await enviarCodigoSeguranca(form.email, 'esqueceuSenha')

                    if(retorno.status === 200){
                        showToast('success',`O código de segurança foi enviado para o e-mail: ${form.email}`)
                        setStepEtapa(step)
                    }
                                        
                    return
                }
    
                if(!_usuarioExistente.message){
                    showToast('warning','E-mail não encontrado em nosso sistema!')
                    return
                }
                
            } 
    
            if(step === 3){
                if(form.codigo === 0 || form.codigo == undefined || form.codigo === '') return showToast('warning','Código não informado!')
                const retorno = await validarCodigoSeguranca(form.email, Number(form.codigo))

                if(retorno.statusCode === 200){                
                    setStepEtapa(step)
                }
                return
            }  
            
            if(step === 4){
                if(form.codigo === '' || form.codigo === 0 || form.codigo === undefined) return showToast('warning','Código não informado!')
                setStepEtapa(step)
            }
    
            if(step === 5){
                if(form.novaSenha === '' || form.novaSenha === undefined) return showToast('warning','Senha não informada!') 
                if(form.confirmarSenha === '' || form.confirmarSenha === undefined) return showToast('warning','Nova Senha não informada!')
                if(form.novaSenha != form.confirmarSenha) return showToast('warning','Senha não confere!')
    
                if(!isStrongPassword(form.novaSenha)){
                    return showToast('warning',<div className='toat-warning-senha'>
                       <span>1. Contém pelo menos uma letra maiúscula (A-Z)</span> <hr></hr>
                       <span>2. Contém pelo menos uma letra minúscula (a-z)</span> <hr></hr>
                       <span>3. Contém pelo menos um caractere numérico (0-9)</span> <hr></hr>
                       <span>{"4. Contém pelo menos um caractere especial '(!@#$%^&*()_+[]{};:|,.<>?)"}</span> <hr></hr>
                       <span>5. Tem pelo menos 8 caracteres</span> 
                    </div>)
                }
    
                if(isStrongPassword){
                    const data = await alterPasswordLegalInsights(form.email, form.novaSenha, form.confirmarSenha)
                    if(data.statusCode !== 400 ){
                        navegate('/')
                    }
                    return
                }
            }
    
            if(step === 6){
                const retorno =  await enviarCodigoSeguranca(form.email, 'esqueceuSenha')

                if(retorno.status === 200){
                    showToast('success',`O código de segurança foi enviado para o e-mail: ${form.email}`)                    
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    const onClickIconStep = (e) => {
        switch(e.currentTarget.id){
            case 'step1':
                setStepEtapa(1)
                break
            case 'step2':
                if(form.email == '') return showToast('warning','E-Mail não informado!')
                setStepEtapa(2)
                break
            case 'step3':
                if(form.email == '') return showToast('warning','Código não informado!')
                setStepEtapa(3)
                break
            case 'step4':
                setStepEtapa(4)
                break              
            default:
                setStepEtapa(1)
                break                                      
        }
    }

    return(<div className='main-container-cadastro-pessoa-juridica'>
        {stepEtapa === 1 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={3} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGEsqueceuSenha}>       

                <div className='conteudo-etapa'>
                    <span className='pessoa-juridica-subheading'>Para começarmos, preencha seu e-mail abaixo:</span>
                    <InputText placeholder='E-mail' name='email' value={form.email} onChange={handleInputChange}/>
                </div>

                <div className='card-etapa-botoes'>
                    <ButtonLabelComponent position={1} label='Voltar' onClick={()=> window.history.back()}/>
                    <ButtonLabelComponent position={2} label='Próximo' onClick={()=> onClickEtapa(2)}/>
                </div>
            </CardEtapas>
        }

        {stepEtapa === 2 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={3} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGEsqueceuSenha}>
                <div className='conteudo-etapa'>
                    <h1 className='pessoa-juridica-header'><b>Digite o código de segurança</b></h1>
                    <span className='pessoa-juridica-subheading' >Digite o código de segurança que foi enviado ao seu 
                    {<br></br>}e-mail. 
                    {<br></br>}Enviamos para {form.email? form.email : ''}</span>
                    <InputText placeholder='Código' value={form.codigo} onChange={handleInputChange} name='codigo'/>  
                    <span className='informe-codigo'>Informe o código acima</span>                  
                </div>

                <div className='card-etapa-botoes'>
                    <ButtonLabelComponent position={1} label='Reenviar e-mail' onClick={()=> onClickEtapa(6)} />
                    <ButtonLabelComponent position={2} label='Próximo' onClick={()=> onClickEtapa(3)}/>
                </div>
            </CardEtapas>
        }


        {stepEtapa === 3 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={3} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGEsqueceuSenha}>
                <span  className='pessoa-juridica-header'><b>Cadastro da senha!</b></span>

                <div className='info-senha'>
                    <span>A senha deve ser de no mínimo 8 dígitos e conter pelo menos uma letra MAIÚSCULA, </span>
                    <span>uma minúscula, um caráter especial (@!*) e um número.</span>
                </div>
                
                <div className='conteudo-etapa'>
                    <InputText type='password' name='novaSenha' value={form.novaSenha} placeholder='Nova Senha' onChange={handleInputChange} />
                    <InputText type='password' name='confirmarSenha' value={form.confirmarSenha} placeholder='Confirmar Senha' onChange={handleInputChange} />                
                </div>

                <div className='card-etapa-botoes'>
                    <ButtonLabelComponent position={2} label='Entrar' onClick={()=> onClickEtapa(5)}/>
                </div>
            </CardEtapas>
        }
        
    </div>)
}

export default EsqueceuSenha