import './index.css'

import React, {useContext, useEffect, useState } from 'react'
import Header from "../../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../../Components/Commons/MenuLateral"
import { LegalInsightsCrudContext } from "../../../../contexts/LegalInsights/LegalInsightsCrudContext"
import { buscarIcon, menuLateralOpcoesPorModulos } from "../../../../utils/Compartilhados"
import MeuPerfil from "../../../MeuPerfil"
import { Box } from '@mui/material'
import DataGridCustom from '../../../../Components/Commons/DataGrid/DataGridCustom'
import { DrropdowPadrao } from '../../../../Components/Commons/Select'
import { ButtonLabelComponent } from '../../../../Components/Commons/Button'
import { DialogAprovacoes, DialogDownload, DialogObservacoes, DialogPadrao } from '../../../../Components/Commons/Dialog'
import { showToast } from '../../../../utils/Compartilhados/CustomToast'
import ReactLoading from 'react-loading'
import useForm from '../../../../hooks/useForm'
import { AuthContext } from '../../../../contexts/LegalInsights/auth'
import { formartarData } from '../../../../utils'



const ProjetoAcordoAprovacoes = () => {
    const { showMenuConfiguracoes, showMeuPerfil,setMenuSelecionado,  
            aprovacaoAprovacoesPendentes,  apiAprovacoesPendentes, apiProjetos  ,
            aprovacaoAprovar, aprovacaoReprovar, aprovacaoForcarPendenteAprovacao, aprovacoesConcluidas, apiAprovacoesConcluidas, getProjetosPorUsuario,
            alterColumnVisibility, columnVisibilityModel,
            loading, setLoading, setModuloSelecionado
    } = useContext(LegalInsightsCrudContext)

    const { user} = useContext(AuthContext)
    
    const [iconAprovacao, setIconAprovacao] = useState(false)
    const [iconCancelamento, setIconCancemento] = useState(false)
    const [iconAprovadoSucesso, setIconAprovadoSucesso] = useState(false)
    const [iconCanceladoSucesso, setIconCanceladoSucesso] = useState(false)
    const [dialog, setDialog]= useState(false)
    const [projetos, setProjetos] = useState([])    
    const [selecionaFase, setSelecionaFase]= useState(null)
    const [selecionaProjetos, setSelecionaProjetos] = useState(null)    
    const [aprovacaoSelecionada, setAprovacaoSelecionada] = useState({})
    const [showModalDownload, setShowModalDownload] = useState(false)
    const [documentosParaDownload, setDocumentosParaDownload] = useState([])
    const [status, setStatus] = useState(3)
    const [aprovacoesAprovadas, _setAprovacoesAprovadas] = useState([])
    const [pendenteAprovacoes, setPendenteAprovacoes] = useState([])
    const [aprovacoesDesaprovadas, setAprovacoesDesaprovadas] = useState([])
    const [form, handleInputChange, clear ] = useForm({ observacoes: '' })

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });


    useEffect(()=>{ 
        setMenuSelecionado("id-menu-aprovacoes")  
        setModuloSelecionado('Projeto Acordo')
       
        if(user?.perfil?.aprovador){
            alterColumnVisibility({ id: false, docmentos: true, dataDecisaoCliente: false  }) 
        }
        
        if(!user?.perfil?.aprovador){
            alterColumnVisibility({ id: false, docmentos:  false, dataDecisaoCliente: false  }) 
        }        
    },[])

    useEffect(()=>{        
       fetchData()
    },[])


    useEffect(()=>{
        setProjetos(apiProjetos)
    },[apiProjetos])

    useEffect(()=>{                
        setPendenteAprovacoes(apiAprovacoesPendentes)
    },[apiAprovacoesPendentes])


    useEffect(()=>{                

        let _aprovado = []
        let _reprovado = []
        apiAprovacoesConcluidas?.map( item => {

            if(item.statusAprovacao === 'Aprovado'){
                _aprovado.push(item)
            }

            if(item.statusAprovacao === 'Reprovado'){
                _reprovado.push(item)
            }
        
            setAprovacoesDesaprovadas(_reprovado)
            _setAprovacoesAprovadas(_aprovado)
        })
    },[apiAprovacoesConcluidas])

    
    const fetchData = async () =>{
        setLoading(true)
        await aprovacaoAprovacoesPendentes()
        await aprovacoesConcluidas()
        await getProjetosPorUsuario()
        setLoading(false)
    }

    const columns = [
    {
        field: "id",
        headerName: "id",
        hide: true,
    },
    {
        field: "docmentos",
        headerName: "Decisão",
        width: 100,
        editable: false,
        renderCell: (event) => renderDecisao(event),
        hide: columnVisibilityModel?.docmentos
    },
    {
        field: "dataDecisaoCliente",
        headerName: status === 1? "Data aprovação" : status === 2? "Data reprovação" : "",
        width: status === 3? 0 : 200,
        editable: false,
        valueFormatter: ({ value }) => value && formartarData(value)
    },

    {
        field: "checklist",
        headerName: "Checklist do processo",
        width: 180,
        editable: false,
        renderCell: (event) => renderCheckList(event)
    },

      
    {
        field: "documentos",
        headerName: "Documentos",
        width: 100,
        editable: false,
        renderCell: (event) => renderCellDocumentos(event)
    },

    {
        field: "processoNr",
        headerName: "Número CNJ",
        width: 200,
        editable: false
    },

    
    {
        field: "grupo",
        headerName: "Grupo",
        width: 200,
        editable: false
    },

    {
        field: "contraparte",
        headerName: "Nome contraparte",
        width: 250,
        editable: false
    },

    {
        field: "advogadoContraparte",
        headerName: "Advogado contraparte",
        width: 280,
        editable: false
    },

    {
        field: "fase",
        headerName: "Fase",
        width: 110,
        editable: false
    },

    {
        field: "momentoProcessual",
        headerName: "Momento",
        width: 320,
        editable: false
    },

    {
        field: "valorProvisaoAtualizado",
        headerName: "Valor provisão",
        width: 150,
        editable: false,
        valueFormatter: ({ value }) => value && currencyFormatter.format(String(value).replace(',', '.'))
    },

    
    {
        field: "valorContingenciaAtual",
        headerName: "Risco",
        width: 150,
        "hide": false,
        valueFormatter: ({ value }) => value && currencyFormatter.format(String(value).replace(',', '.'))
    },
        
    {
        field: "valorMeta",
        headerName: "Gatilho Bruto",
        width: 150,
        editable: false,
        valueFormatter: ({ value }) => value && currencyFormatter.format(String(value).replace(',', '.'))
    },

    {
        field: "valorAcordo",
        headerName: "Acordo Bruto",
        width: 150,
        editable: false,
        valueFormatter: ({ value }) => value && currencyFormatter.format(String(value).replace(',', '.'))
    },



    {
        field: "valorAcordoLiquido",
        headerName: "Acordo Líquido",
        width: 150,
        editable: false,
        valueFormatter: ({ value }) => value && currencyFormatter.format(String(value).replace(',', '.'))
    },    

    {
        field: "desagio",
        headerName: "Deságio",
        width: 100,
        editable: false,
        valueFormatter: ({ value }) => `${(value * 100).toFixed(2)}%`

    },

    {
        field: "valorAnalise",        
        headerName: "Honorários Pact",
        width: 170,
        editable: false,
        valueFormatter: ({ value }) => value && currencyFormatter.format(String(value).replace(',', '.'))
    }

    ]

    const renderCheckList = (event) => {
        
        const openTextNovaAba = (text) =>{

            if(text){
                const newWindow = window.open('', '_blank');
                newWindow.document.open();
                newWindow.document.write(`<html><body><pre>${text}</pre></body></html>`);
                newWindow.document.close();
                newWindow.document.title = "LegalInsights"                
            }
            
        }

        return(<div className='checklist-detalhe'>
            <p onClick={()=> openTextNovaAba(event.row.checklist) }>Detalhe</p>
        </div>)
    }

    const renderDecisao = (event) => {
        return(<div className='renderCell-icons'>
            {event?.row?.statusAprovacao === "Aprovado" && <img src={buscarIcon('Icon4Checked')} alt="" onClick={()=>onClickAprovacao(event)} /> }
            {event?.row?.statusAprovacao === "Reprovado"&& <img src={buscarIcon('Icon5Close')} alt="" onClick={()=>onClickCancelarAprovacao(event)} />}
            {event?.row?.statusAprovacao === "Pendente" && <>
                <img src={event?.row?.statusAprovacao === "Pendente" && buscarIcon('Icon3Checked')} alt="" onClick={()=>onClickAprovacao(event)} />
                <img src={event?.row?.statusAprovacao === "Pendente" && buscarIcon('Icon7Close') } alt="" onClick={()=>onClickCancelarAprovacao(event)} />
            </> }
            
            
        </div>)
    }

    const onClickAprovacao = (event) => {
        setIconAprovacao(!iconAprovacao)
        setAprovacaoSelecionada({
            id: event.row.id,
            processoNr: event.row.processoNr,
            statusAprovacao: event.row.statusAprovacao,
            row: event.row            
        })
    }

    const onClickCancelarAprovacao = (event) => {       
        setIconCancemento(!iconCancelamento)
        setAprovacaoSelecionada({
            id: event.row.id,
            processoNr: event.row.processoNr,
            statusAprovacao: event.row.statusAprovacao,
            cancelado: true,
            row: event.row
        })
    }

    const renderCellDocumentos = (event) => {
        return(<div className='renderCell-icons'>
            <img src={buscarIcon('Icon13Documento')} alt='Documento download' onClick={()=> onClickDocumentos(event)}/>
        </div>)
    }

    const onClickDocumentos = (event)=>{
        setShowModalDownload(!showModalDownload)
        setDocumentosParaDownload(event.row.documentos)
    }

    const onClickCancelar =async () => {

        if(aprovacaoSelecionada.statusAprovacao === "Reprovado"){
            setIconCancemento(false)
            setIconCanceladoSucesso(false)            
            
            const response = await aprovacaoForcarPendenteAprovacao(aprovacaoSelecionada.processoNr, form.observacoes)
            clear()
            await fetchData()
            
            if(response?.status === 200 & response.data.status !== 500 ){             
                showToast('success','Desaprovação do termo cancelada com sucesso!')
            }else{
                showToast('Não foi possível cancelar realizar a desaprovação!')
            }
        }

        if(aprovacaoSelecionada.statusAprovacao === "Pendente"){
            setIconCancemento(false)
            setIconCanceladoSucesso(!iconCanceladoSucesso)

            const response = await aprovacaoReprovar(aprovacaoSelecionada.processoNr, form.observacoes)

            if(response?.status === 200 & response?.data?.status !== 500 ){             
                showToast('success','Desaprovação do termo cancelada com sucesso!')
            }else{
                showToast('Não foi possível cancelar realizar a desaprovação!')
            }

        } 

        await aprovacaoAprovacoesPendentes()
        await aprovacoesConcluidas()
        setAprovacaoSelecionada({})
    }

    const onClickAprovar = async () => {
        if(aprovacaoSelecionada.statusAprovacao === "Aprovado"){
            setIconAprovacao(false)
            setIconAprovadoSucesso(false)
            
            const response = await aprovacaoForcarPendenteAprovacao(aprovacaoSelecionada.processoNr, form.observacoes)   
            clear()
            if(response?.status === 200 & response.data.status !== 500 ){             
                showToast('success','Cancelamento da aprovação realizado com sucesso!')         
            }else{
                showToast('Não foi possível cancelar a aprovação!')
            }
        }

        if(aprovacaoSelecionada.statusAprovacao === "Pendente"){
            setIconAprovacao(false)
            setIconAprovadoSucesso(!iconAprovadoSucesso)
            const response = await aprovacaoAprovar(aprovacaoSelecionada.processoNr, form.observacoes)      
            clear()

            if(response?.status === 200 & response?.data?.status !== 500 ){ 
                await aprovacaoAprovacoesPendentes()
                await aprovacoesConcluidas()
                setAprovacaoSelecionada({})

                return showToast('success','Aprovação realizada com sucesso!')      
            }else{
                setAprovacaoSelecionada({})
                return showToast('warning','Não foi possível aprovar sua requisição!')
            }
        } 
    }    

    const onClickFecharDialog = () => {
        setIconAprovacao(false)
        setIconCancemento(false)
        setAprovacaoSelecionada({})
    }

    const LimparFiltro = async () =>{
        getProjetosPorUsuario()
        aprovacaoAprovacoesPendentes()        
        aprovacoesConcluidas()
        setSelecionaProjetos(apiProjetos)
        setSelecionaFase(null)
        showToast('info','Filtro limpo com sucesso!')
    }

    const filtroAprovacoesFase = (codigoFase) => {
        setSelecionaFase(codigoFase)

            if(codigoFase === 1){                
                filtroFase("1 - Conhecimento")
            }  

            if(codigoFase === 2){
                filtroFase("2 - Recursal")                
            }

            if(codigoFase === 3){
                filtroFase("3 - Execução")                
            }            
    }

    const filtroFase = (fase) => {
        let _pendenteAprovacoes = []
        _pendenteAprovacoes = apiAprovacoesPendentes.filter( item => { return item.fase === fase })
        setPendenteAprovacoes(_pendenteAprovacoes)
        
        let _aprovadas = []
        _aprovadas = apiAprovacoesConcluidas.filter( item => { return item.fase === fase && item.statusAprovacao === "Aprovado" })
        _setAprovacoesAprovadas(_aprovadas)

        let _desaprovada = []
        _aprovadas = apiAprovacoesConcluidas.filter( item => { return item.fase === fase && item.statusAprovacao === "Reprovado" })
        setAprovacoesDesaprovadas(_desaprovada)
    }


    const filtroAprovacoesProjetos = (projetosGrupoID) => {
        setSelecionaProjetos(projetosGrupoID)

        let _pendenteAprovacoes = []
        _pendenteAprovacoes = apiAprovacoesPendentes.filter( item => { return item.grupoID ===  projetosGrupoID })
        setPendenteAprovacoes(_pendenteAprovacoes)
        
        let _aprovadas = []
        _aprovadas = apiAprovacoesConcluidas.filter( item => { return item.grupoID ===  projetosGrupoID })
        _setAprovacoesAprovadas(_aprovadas)

        let _desaprovada = []
        _aprovadas = apiAprovacoesConcluidas.filter( item => { return item.grupoID ===  projetosGrupoID })
        setAprovacoesDesaprovadas(_desaprovada)
    }



    return(
        <div className="modulo-projeto-acordos-container">
            <Header />   

            <div className="projeto-acordo-container">
                <div className="projeto-acordo-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('projetoAcordo', user?.perfil)}/>
                </div>

                <div className="projeto-acordo-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            
                            <div className="meus-processos-filtros">
                                <DrropdowPadrao label={"Status"} 
                                    optionLabel={"label"} 
                                    options={[{ value: 1, label: 'Aprovado'}, { value: 2, label: 'Desaprovado' }, { value: 3, label: 'Pendente aprovação' }]} 
                                    onChange={(e)=> { 
                                        setStatus(e.value)

                                    if(user?.perfil?.aprovador){
                                        if(e.value === 1 ){
                                            alterColumnVisibility({ docmentos: false, id: false, dataDecisaoCliente: true })                                                       
                                        }

                                        if(e.value === 2 || e.value === 3){
                                            alterColumnVisibility({ docmentos: true, id: false,  dataDecisaoCliente: e.value === 3? false : true })
                                        }
                                    }
                                        

                                    if(!user?.perfil?.aprovador){
                                        alterColumnVisibility({ docmentos: false, id: false, dataDecisaoCliente: false  })
                                    }
                                                         
                                    }} 
                                    value={status} 
                                />

                                <DrropdowPadrao label={"Projetos"} optionLabel={"label"} options={projetos} value={selecionaProjetos} onChange={(e)=>filtroAprovacoesProjetos(e.value)} />
                                <DrropdowPadrao label={"Fase"} 
                                    optionLabel={"label"} 
                                    options={[{ value: 1, label: '1 - Conhecimento'}, { value: 2, label: '2 - Recursal' }, { value: 3, label: '3 - Execução' }]} 
                                    onChange={(e)=> filtroAprovacoesFase(e.value)} 
                                    value={selecionaFase} 
                                />                               
                                <ButtonLabelComponent label={`Limpar filtro`} onClick={LimparFiltro} info />

                            </div>
    
 
                            
                            <div className="data-grid-meus-processos">
                                {loading? 
                                    <div className="loadProcessos">
                                        <ReactLoading type='spin' color='#4e1975' />
                                    </div> 
                                :
                                    <Box sx={{ height: '80vh', width: '100%' }}>
                                        <DataGridCustom                                             
                                            column={columns} 
                                            rows={status === 3? pendenteAprovacoes : status === 2? aprovacoesDesaprovadas : status === 1? aprovacoesAprovadas : []} 
                                            selection={false}                                                       
                                        />
                                    </Box>
                                }
                            </div>
                    </div>                    
                </div>

                <div className="projeto-acordo-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div> 

                {aprovacaoSelecionada.statusAprovacao === "Pendente" && 
                    <DialogObservacoes  
                    onClickConfirmar={onClickAprovar} 
                    onclicCancelar={onClickFecharDialog} 
                    visibleDialog={iconAprovacao}
                    mensagem={`Confirma aprovação dos termos do acordo deste Processo Nº ${aprovacaoSelecionada.processoNr}?`}
                    handleInputChange={handleInputChange}
                    observacoes={form.observacoes}
                    nomeCampoTextArea={'observacoes'}
                /> }      
                {aprovacaoSelecionada.statusAprovacao === "Aprovado" && <DialogAprovacoes  onClickConfirmar={onClickAprovar} onclicCancelar={onClickFecharDialog} visibleDialog={iconAprovacao} mensagem={`Tem certeza que deseja cancelar a aprovação desse processo Nº ${aprovacaoSelecionada.processoNr}?`}/> }      
                {aprovacaoSelecionada.cancelado === true && aprovacaoSelecionada.statusAprovacao !== "Reprovado" &&<DialogObservacoes onClickConfirmar={onClickCancelar} onclicCancelar={onClickFecharDialog} visibleDialog={iconCancelamento} mensagem={`Tem certeza que desaprovar os termos do acordo deste Processo  Nº ${aprovacaoSelecionada.processoNr}?`}/> }      
                {aprovacaoSelecionada.statusAprovacao === "Reprovado" && aprovacaoSelecionada.cancelado === true && <DialogObservacoes onClickConfirmar={onClickCancelar} onclicCancelar={onClickFecharDialog} visibleDialog={iconCancelamento} mensagem={`Tem certeza que deseja cancelar a desaprovação deste Processo Nº ${aprovacaoSelecionada.processoNr}?`}/> } 
                {dialog && <DialogPadrao mensagem={' Estabelecer claramente os objetivos do evento. Determinar o público-alvo e tamanho estimado do evento. Definir uma data e horário para o evento.'} visibleDialog={dialog} hide={()=> setDialog(!dialog)}/> } 
                {showModalDownload && <DialogDownload visibleDialog={showModalDownload} mensagem={'Documentos para downloads:'} colunas={documentosParaDownload} onclicCancelar={()=> setShowModalDownload(!showModalDownload)}/>}
            </div>  
        </div>
    )
}

export default ProjetoAcordoAprovacoes
