import React from 'react'
import CustomCard from '../../Components/LegalInsights/CustomCard'
import logo from '../../assets/Modulos/LegalInsights/Logo-Legal-Insights-v2.svg'
import './index.css'

import { useNavigate } from 'react-router-dom'
import IconPessoaFisica from '../../assets/Icons/19.png'
import IconPessoaJuridica from '../../assets/Icons/11.png'

const CadastrarSe = () => {
    const navegate = useNavigate();

    const onClickNavegate = (caminho) => {
        navegate(`/${caminho}`)
    }

    return(<div className='main-container-cadastrar-se'>
        <div className='container-cadastrar-se'>
            <div className='cadastrar-se-header'>
                <h1>Comece sua jornada</h1>
                <h1>com a gente</h1>    
            </div>
            <div className='cadastrar-se-logo'>
                <img src={logo} alt="legal-insights" />                
            </div>  
        </div>
        <div className='cadastrar-se-title'>
            <h1>Como deseja se cadastrar?</h1>
        </div>

        <div className='cadastrar-se-juridico-fisico'>
            <CustomCard 
                icon={IconPessoaFisica}
                titulo={'Pessoa Física'}
                descricao={`Para profissionais autônomos`}
                label={'Seguir cadastro'}
                onClick={()=> onClickNavegate('cadastroPessoaFisica')}
            />

            <CustomCard 
                icon={IconPessoaJuridica}
                titulo={'Pessoa Jurídica'}
                descricao={`Para representantes, funcionários e sócios de empresas`}
                label={'Seguir cadastro'}
                onClick={()=> onClickNavegate('cadastroPessoaJuridica')}
            />
            
        </div>
    </div>)
}

export default CadastrarSe