import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../../contexts/LegalInsights/auth';
import { CrudContext } from '../../../contexts/crud';
import Header from '../../../Components/Commons/Header'
import { toast } from 'react-toastify'
import { FilterMatchMode } from 'primereact/api';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import '../../../index.css';
import './index.css'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { ImportacaoContext } from '../../../contexts/importacao';
import { readExcelFile, dateTemplete } from '../../../utils';
import DataGridCustom from "../../../Components/Commons/DataGrid/DataGridCustom";
import Box from '@mui/material/Box';
import * as XLSX from 'xlsx';

const Higienizacao = () => {

    const { load } = useContext(ImportacaoContext);
    const { isHumburguerActive } = useContext(AuthContext);
    const { cadastrarNovoProcesso, higienizacaoAllProcess, higienizacaoProcess, realizarHigienizacao, processoAndamento
        ,formConteudo
    } = useContext(CrudContext);
    const [dicionario, setdicionario] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [filters1, setFilters1] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue1] = useState('');
    const [newProductsDialog, setNewProductsDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [fileData, setFileData] = useState()
    const ref = useRef();
    const [isShowAndamento, setIsShowAndamentos] = useState(false)
    const [dataAndamento, setDataAndamento] = useState()

    useEffect(() => {
        initFilters1();
        higienizacaoAllProcess()
    }, [])

    useEffect(() => {
        setdicionario(higienizacaoProcess)
    }, [higienizacaoProcess])


    useEffect(() => {
        setDataAndamento(processoAndamento)
    }, [processoAndamento])

    useEffect(()=>{ setSelectedCustomers(formConteudo)},[formConteudo])

    const onGlobalFilterChange1 = (e) => {
        const value = e.target.value;
        let _filters1 = { ...filters1 };
        _filters1['global'].value = value;

        setFilters1(_filters1);
        setGlobalFilterValue1(value);
    }

    const initFilters1 = () => {
        setFilters1({
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS }

        });
        setGlobalFilterValue1('');
    }

    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue1} onChange={onGlobalFilterChange1} placeholder="Pesquisar" />
                </span>
            </div>
        )
    }

    const header1 = renderHeader1();

    const atualizar = () => {
        realizarHigienizacao();
        setImportDialog(true);
    }

    const exportExcel = () => {
        if (selectedCustomers.length > 0) {
            let itemExcel = []
            let itemGrid = dicionario.filter( item => selectedCustomers.includes(item.id))


            itemGrid.map(item => {
                itemExcel.push({
                    codProcesso: item.codProcesso,
                    numProcesso: item.numProcesso,
                    status: item.status,
                    nomeSistema: item.nomeSistema,
                    PJE: handleSiglaSistemaJPE(item),
                    digital: item.digital,
                    comarca: item.comarca,
                    tribunal: item.tribunal,
                    vara: item.vara,
                    assunto: item.assunto,
                    natureza: item.natureza,
                    tipoAcao: item.tipoAcao,
                    juiz: item.juiz,
                    advogadoAtivo: handleAdvogadoAtivo(item, 1),
                    advogadoPassivo: handleAdvogadoPassivo(item, 1),
                    andamentos: handleAndamentos(item, 1),
                    dataDistribuicao: item.dataDistribuicao,
                    valor: item.valor
                })
            })

            import('xlsx').then(xlsx => {
                const headers = {
                    codProcesso: 'Código do Processo',
                    numProcesso: 'Número do Processo',
                    status: 'Status',
                    nomeSistema: 'Nome do Sistema',
                    PJE: 'PJE',
                    digital: 'Digital',
                    comarca: 'Comarca',
                    tribunal: 'Tribunal',
                    vara: 'Vara',
                    assunto: 'Assunto',
                    natureza: 'Natureza',
                    tipoAcao: 'Tipo Ação',
                    juiz: 'Juiz',
                    advogadoAtivo: 'Advogado Ativo',
                    advogadoPassivo: 'Advogado Passivo',
                    andamentos: 'Andamentos',
                    dataDistribuicao: 'Data Distribuição',
                    valor: 'Valor'
                };

                itemExcel.unshift(headers); // if custom header, then make sure first row of data is custom header 
                const worksheet = XLSX.utils.json_to_sheet(itemExcel, { skipHeader: true });
                //  worksheet = xlsx.utils.json_to_sheet(productsFiltered);
                const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                saveAsExcelFile(excelBuffer, 'processos');
            });
        } else {
            toast.error('Selecione ao menos um item para exportar');
        }

    }

    const saveAsExcelFile = (buffer) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                const date = new Date();
                let formatedDay = date.toLocaleDateString();
                module.default.saveAs(data, 'Higienizacao_' + formatedDay + EXCEL_EXTENSION);
            }
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-file-excel" className="p-button-success mr-2" type="button" data-pr-tooltip="XLS" onClick={exportExcel} />
                <Button label={"Importar"} icon={"pi pi-download"} className={"p-button-warning"} onClick={() => setNewProductsDialog(true)} />
                <Button label="Atualizar" icon="pi pi-refresh" className="p-button-info" type="button" onClick={() => atualizar()} />
            </React.Fragment>
        )
    }

    const importarArquivo = () => {

        
        if (fileData) {
            for (let index = 0; index < fileData.length; index++) {
                const element = fileData[index];
                cadastrarNovoProcesso({ numProcesso: element.NCNJ, baseCliente: element.baseCliente })
            }
            setNewProductsDialog(false)
            setImportDialog(true)
        }
        else {
            toast.error("Necessário selecionar um arquivo")
        }


    }

    const hideDialog = () => {
        setNewProductsDialog(!newProductsDialog)
    };

    const handleFile = async (e, ref) => {
        const json = await readExcelFile(e, ref)
        setFileData(json)
    }

    const handleSiglaSistemaJPE = (rowData) => {

        if (rowData.siglaSistema) {
            const sigla = rowData.siglaSistema.slice(-3)
            if (sigla.toUpperCase() === 'PJE') {
                return 'Sim'
            }

            if (sigla != 'JPE') {
                return 'Não'
            }
        }
        else {
            return 'Não'
        }

    }

    const hideImportDialog = () => {
        setImportDialog(false);
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="OK" icon="pi pi-check" className="p-button-text" onClick={hideImportDialog} />
        </React.Fragment>
    );


    const handleAdvogadoAtivo = (rowData, itemExcel) => {
        let advogadoAtivoContact = ''
        const autores = rowData.autor
        const advogadoProcesso = rowData.advogadoProcesso
        advogadoProcesso.forEach(advProcess => {
            autores.filter(autor => {
                if (autor.codProcessoPolo === advProcess.codProcessoPolo) {
                    if (advogadoAtivoContact === '') {
                        advogadoAtivoContact = advProcess.nome
                    } else {
                        advogadoAtivoContact = advogadoAtivoContact + ' | ' + advProcess.nome
                    }
                }
                return advogadoAtivoContact
            })
        });

        let arr = advogadoAtivoContact.split(" | ")
        let arrSemDuplicatas = arr.filter((item, index) => arr.indexOf(item) === index);
        advogadoAtivoContact = arrSemDuplicatas.join(" | ");

        if (itemExcel === 1) {
            return advogadoAtivoContact
        } else {
            return <span className="tooltip" title={advogadoAtivoContact}>{advogadoAtivoContact ? advogadoAtivoContact.substring(0, 30) + '...' : ''}</span>
        }
    }

    const handleAdvogadoPassivo = (rowData, itemExcel) => {
        let advogadoPassivoContact = ''
        const reus = rowData.reu
        const advogadoProcesso = rowData.advogadoProcesso
        advogadoProcesso.forEach(advProcess => {
            reus.filter(autor => {
                if (autor.codProcessoPolo === advProcess.codProcessoPolo) {
                    if (advogadoPassivoContact === '') {
                        advogadoPassivoContact = advProcess.nome
                    } else {
                        advogadoPassivoContact = advogadoPassivoContact + ' | ' + advProcess.nome
                    }
                }
                return advogadoPassivoContact
            })
        });

        let arr = advogadoPassivoContact.split(" | ")
        let arrSemDuplicatas = arr.filter((item, index) => arr.indexOf(item) === index);
        advogadoPassivoContact = arrSemDuplicatas.join(" | ");

        if (itemExcel === 1) {
            return advogadoPassivoContact
        } else {
            return <span className="tooltip" title={advogadoPassivoContact}>{advogadoPassivoContact ? advogadoPassivoContact.substring(0, 30) + '...' : ''}</span>
        }
    }


    const handleAndamentos = (rowData, itemExcel) => {
        let andamentos = ''
        const andamentosProcesso = rowData.andamentos
        andamentosProcesso.forEach(andamento => {
            if (andamentos === '') {
                andamentos = andamento.textoAndamento
            } else {
                andamentos = andamentos + ' ; ' + andamento.textoAndamento
            }
        });

        if (itemExcel === 1) {
            return andamentos
        } else {
            return <span className="tooltip" title={andamentos}>{andamentos ? andamentos.substring(0, 40) + '...' : ''}</span>
        }
    }

    const onHideLogDetalhe = () => {
        setIsShowAndamentos(false)
    }

    const columns = [
        {field: 'id', headerName: 'id', width: 50 },
        {field: 'codProcesso', headerName: 'Código do Processo', width: 150 },
        {field: 'baseCliente', headerName: 'Base Cliente', width: 150 },
        {field: 'numProcesso', headerName: 'Número do Processo', width: 200 },
        {field: 'status', headerName: 'Status', width: 150 },
        {field: 'nomeSistema', headerName: 'Nome do Sistema', width: 350 },
        {field: 'PJE', headerName: 'PJE', width: 200 },
        {field: 'digital', headerName: 'digital', width: 150 },
        {field: 'comarca', headerName: 'Comarca', width: 150 },
        {field: 'tribunal', headerName: 'Tribunal', width: 350 },
        {field: 'vara', headerName: 'Vara', width: 350 },
        {field: 'assunto', headerName: 'Assunto', width: 500 },
        {field: 'natureza', headerName: 'Natureza', width: 250 },
        {field: 'tipoAcao', headerName: 'Tipo de Ação', width: 250 },
        {field: 'juiz', headerName: 'Juiz', width: 250 },
        {field: 'advogadoAtivo', headerName: 'Advogado Ativo', width: 250 },
        {field: 'advogadoPassivo', headerName: 'Advogado Passivo', width: 250 },
        {field: 'dataDistribuicao', headerName: 'Data de Distribuição', width: 250 },
        {field: 'valor', headerName: 'Valor', width: 250 },
        {field: 'andamentos', headerName: 'Andamentos recentes', width: 250 },
        {field: 'andamento', headerName: 'Todos Andamentos', width: 250 },
      ]

    return (
        <div>
            <Header />
            <div className={`content${isHumburguerActive ? '-active' : ''}`}>
                <div className="container-profile">
                    {dicionario &&
                        <div className="card p-fluid">
                            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                            <Box sx={{ height: 800, width: '100%' }}>
                                <DataGridCustom 
                                    column={columns} 
                                    rows={dicionario}                
                                />
                            </Box> 
                        </div>
                    }
                </div>

                {newProductsDialog &&
                    <Dialog
                        visible={newProductsDialog}
                        style={{ width: "450px" }}
                        header="Importação"
                        modal
                        className="card p-fluid"
                        onHide={hideDialog}
                    >
                        <div className="field">
                            <a className="arqModelo" href='https://pactfy.s3.sa-east-1.amazonaws.com/Modelo_Imporatacao_Higienizacao.xlsx'>Baixar Arquivo Modelo</a>
                        </div>

                        <div className="field">
                            <input id="arquivo-empresa" type="file" className="upload-box" onChange={(e) => handleFile(e, ref)} ref={ref} />
                        </div>

                        <div className="field button-right">
                            <Button
                                label={'Enviar'}
                                id={'button-right'}
                                className="p-button-success mr-2"
                                onClick={() => { importarArquivo() }}
                                style={{ width: '100px', margin: '1rem 0' }} disabled={load}
                            />
                        </div>
                    </Dialog>
                }

                {importDialog &&
                    <Dialog visible={importDialog} style={{ width: '450px' }} header="Atenção" modal footer={deleteProductDialogFooter} onHide={hideImportDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '1rem' }} />
                            {importDialog && <span> Sua base está sendo processada! As informações serão exibidas em 24 horas.</span>}
                        </div>
                    </Dialog>
                }


                {isShowAndamento &&
                    <div className="container-profile">

                        <Dialog
                            visible={isShowAndamento}
                            style={{ width: "auto" }}
                            header="Andamentos"
                            modal
                            className="card p-fluid"
                            onHide={onHideLogDetalhe}
                        >
                            <div className="card p-fluid">

                                <DataTable className="datatable-main" id="datatable-main" value={dataAndamento} editMode="row" dataKey="_id"
                                    globalFilterFields={['codAndamento', 'textoAndamento', 'codProcesso']} responsiveLayout="scroll"
                                    filters={filters1}
                                    header={header1}
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Exibindo {first} até {last} de {totalRecords}" rows={100} rowsPerPageOptions={[50, 100, 150, 200]}
                                >
                                    <Column headerStyle={{ width: '1%' }}></Column>
                                    <Column field="codAndamento" header="Código do Andamento" style={{ width: '5%' }}></Column>
                                    <Column field="textoAndamento" header="Texto do Andamento" style={{ width: '3%' }}></Column>
                                    <Column field="codProcesso" header="Código do Processo" style={{ width: '5%' }}></Column>
                                    <Column field="codAgrupador" header="Código do Agrupador" style={{ width: '5%' }}></Column>
                                    <Column field="numProcesso" header="Número do Processo" style={{ width: '5%' }}></Column>
                                    <Column field="codEscritorio" header="Código do Escritório" style={{ width: '5%' }}></Column>
                                    <Column field="dataAndamento" sortable={true} body={(rowData) => dateTemplete(rowData, "dataAndamento")} header="Data Andamento" style={{ width: '5%' }}></Column>
                                    <Column headerStyle={{ width: '1%', minWidth: '2rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>
                            </div>

                        </Dialog>
                    </div>
                }


            </div >
        </div >
    )
}


export default Higienizacao;