import React, { useContext, useEffect, useState } from 'react'
import './index.css'
import CardEtapas from '../../Components/LegalInsights/CardEtapas'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'
import { InputText } from 'primereact/inputtext'
import useForm from '../../hooks/useForm'
import IMGEsqueceuSenha from '../../assets/Imagens/3.png'
import { ButtonLabelComponent } from '../../Components/Commons/Button'
import { showToast } from '../../utils/Compartilhados/CustomToast'
import { isStrongPassword } from '../../utils/Compartilhados/Validacoes'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/LegalInsights/auth'

const PrimeiroAcesso = () => {    
    const [form, handleInputChange ] = useForm({ 
        email: '', 
        codigo: '',
        novaSenha: '',
        confirmarSenha: ''
    }) 

    const [usuario, setUsuario] = useState()

    const { stepEtapa, 
            alterPasswordLegalInsights 
    } = useContext(LegalInsightsCrudContext)

    const { user } = useContext(AuthContext)
    
    useEffect(()=>{
        if(user){
            setUsuario(user)
        }
    },[])
    
    const navegate = useNavigate()
    let headerTitulo = 'Primeiro Acesso'

    const onClickEtapa = async (step) => {

        if(step === 1){
            if(form.novaSenha === '' || form.novaSenha === undefined) return showToast('warning','Senha não informada!') 
            if(form.confirmarSenha === '' || form.confirmarSenha === undefined) return showToast('warning','Nova Senha não informada!')
            if(form.novaSenha !== form.confirmarSenha) return showToast('warning','Senha não confere!')

            if(!isStrongPassword(form.novaSenha)){
                return showToast('warning',<div className='toat-warning-senha'>
                   <span>1. Contém pelo menos uma letra maiúscula (A-Z)</span> <hr></hr>
                   <span>2. Contém pelo menos uma letra minúscula (a-z)</span> <hr></hr>
                   <span>3. Contém pelo menos um caractere numérico (0-9)</span> <hr></hr>
                   <span>{"4. Contém pelo menos um caractere especial '(!@#$%^&*()_+[]{};:|,.<>?)"}</span> <hr></hr>
                   <span>5. Tem pelo menos 8 caracteres</span> 
                </div>)
            }

            if(isStrongPassword){
                const data = await alterPasswordLegalInsights(usuario.email, form.novaSenha, form.confirmarSenha)
                if(data.statusCode !== 400 ){
                    navegate('/')
                }
                return
            }
        }
    }

    return(<div className='main-container-cadastro-pessoa-juridica'>
        {stepEtapa === 1 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} imagem={IMGEsqueceuSenha}>
                <span  className='pessoa-juridica-header'><b>Cadastro da senha!</b></span>

                <div className='info-senha'>
                    <span>A senha deve ser de no mínimo 8 dígitos e conter pelo menos uma letra MAIÚSCULA, </span>
                    <span>uma minúscula, um caráter especial (@!*) e um número.</span>
                </div>
                
                <div className='pessoa-juridica-conteudo'>
                    <InputText type='password' name='novaSenha' value={form.novaSenha} placeholder='Nova Senha' onChange={handleInputChange} />
                    <InputText type='password' name='confirmarSenha' value={form.confirmarSenha} placeholder='Confirmar Senha' onChange={handleInputChange} />                
                </div>

                <div className='pessoa-juridica-botoes'>
                    <ButtonLabelComponent position={2} label='Entrar' onClick={()=> onClickEtapa(1)}/>
                </div>
            </CardEtapas>
        }
        
    </div>)
}

export default PrimeiroAcesso