import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLabelComponent } from '../../Commons/Button'
import './index.css'

const CustomCard = ({ icon, titulo, descricao, label, onClick }) => {
    
    return(<div className="custom-card-main-container">
        <div className='custom-container'>
            <div className='custom-container-icon'>
                <img src={icon} alt="" />
            </div>
            
            <div className='custom-container-titulo'>
                <h1>{titulo}</h1>
            </div>
            
            <div className='custom-container-descricao'>
                <span>{descricao}</span>
            </div>
            
            <div className='custom-container-botao'>
                <ButtonLabelComponent position={2} onClick={onClick} label={label} />
            </div>            
        </div>            
    </div>)
}

CustomCard.propTypes = {
    icon: PropTypes.string,
    titulo: PropTypes.string,
    descricao: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func    
}

export default CustomCard 