import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import axios from 'axios';

export const CrudContext = createContext({})

function CrudProvider({ children }) {
    
    const [higienizacaoProcess, setHigienizacaoProcess] = useState([])
    const [processoAndamento, setProcessoAndamento] = useState([])
    const [formConteudo, setFormConteudo]= useState()

    const urlBaseSolucionare = "https://pactfy-solucionare.up.railway.app";


    const cadastrarNovoProcesso = async (ncnj) => {
        await axios.post(urlBaseSolucionare + '/process/CadastraNovoProcesso', ncnj)
            .then(function (response) {
                axios.get(`${urlBaseSolucionare}/process/BuscaStatusProcesso?codProcesso=${response.data.codProcesso}`)
                    .then(response => { console.log("BuscaStatusProcesso:", response) })
                    .catch(error => { console.log(error) }).finally(() => higienizacaoAllProcess())
            })
            .catch(err => {
                console.log(err)
            })
    }

    const realizarHigienizacao = async () => {
        await axios.get(urlBaseSolucionare + '/realizaHigienizacao')
            .then( ()=> { })
            .catch(err => {
                console.log(err)
            }).finally(() => higienizacaoAllProcess())
    }


    const higienizacaoAllProcess = async () => {
        try {
            await axios.get(urlBaseSolucionare + '/process/findAllProcess')
                .then(response => {
                    setHigienizacaoProcess(response.data)
                })
                .catch(() => { toast.error('Ocorreu um erro') })
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    const buscaNovosAndamentosPorProcesso = async (codProcesso) => {
        try {
            await axios.get(`${urlBaseSolucionare}/buscaNovosAndamentos/${codProcesso}`)
                .then(response => {
                    setProcessoAndamento(response.data)
                })
                .catch(() => { toast.error('Ocorreu um erro') })
        } catch (error) {
            toast.error("Algo deu errado")
        }
    }

    return (
        <CrudContext.Provider value={{         
            higienizacaoAllProcess,
            higienizacaoProcess,
            cadastrarNovoProcesso,
            realizarHigienizacao,
            buscaNovosAndamentosPorProcesso,
            processoAndamento,
            formConteudo, setFormConteudo
        }}>
            {children}
        </CrudContext.Provider>
    )
}

CrudProvider.propTypes = {
    children: PropTypes.node,
}

export default CrudProvider;