import React, { useState, useContext } from 'react'
import './styled.css'


import { AuthContext } from '../../contexts/LegalInsights/auth'
import { Link } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password'
import { Divider } from 'primereact/divider';

import logo from '../../assets/Modulos/LegalInsights/Logo-Legal-Insights-v2.svg'
import { toast } from 'react-toastify';
import { showToast } from '../../utils/Compartilhados/CustomToast';
import useForm from '../../hooks/useForm';
import { Checkbox } from 'primereact/checkbox'
import { isStrongPassword } from '../../utils/Compartilhados/Validacoes';
import { FaWhatsapp } from "react-icons/fa"
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext';

const SignIn = ()=> {
  const { signIn, alterPasswordLegalInsights, storageUser, usuarioAuth, setUser } = useContext(AuthContext);
  const {  setShowModalConfiguracoes } = useContext(LegalInsightsCrudContext)
  const [exibeSenha, setExibeSenha] = useState(false);
  const [isPrimeiroAcesso, setIsPrimeiroAcesso] = useState(false)

  const [loginRealizado, setLoginRealizado]= useState(false)
  const [form, handleInputChange] = useForm({ 
    email: '', 
    novaSenha: '', 
    password: '',
    confirmarNovaSenha: '',     
    aceiteLegalInsights: false, 
    aceiteContatoWhatsApp: false    
  }) 

  const handledSubmit = async (e) =>{
    e.preventDefault();

    let response

    if(!loginRealizado){
      if(form.email === '' || form.password === '') {
        return toast.warning('Por favor, informar um e-mail e senha!')
      }
  
      if (form.email !== '' && form.password !== '') {
         response = await signIn(form.email, form.password)
         setShowModalConfiguracoes(false)
         if(response?.isPrimeiroAcesso){
            setIsPrimeiroAcesso(response?.isPrimeiroAcesso)
            setLoginRealizado(!loginRealizado)
         }
      }
    }
    
    if(loginRealizado){
      if(form.novaSenha === '') return showToast('warning','Nova senha não informada!')
      if(form.confirmarNovaSenha === '') return showToast('warning','Confirmar nova senha não informada!')
      if(form.novaSenha !== form.confirmarNovaSenha) return showToast('warning','Senha não confere!')
      if(!form.aceiteLegalInsights) return showToast('warning','Aceite do termo de uso e política de privacidade da Legal Insigths é obrigatório!')

      if(!isStrongPassword(form.novaSenha)) return showToast('warning','A senha fornecida não atende aos requisitos mínimos de segurança.')

      if(isStrongPassword(form.novaSenha)){
        const responseAlt = await alterPasswordLegalInsights(form.email, form.novaSenha, form.confirmarNovaSenha)
        if(responseAlt.length > 0){
          return showToast('warning',responseAlt)
        }
  
        usuarioAuth.isPrimeiroAcesso = false
        storageUser(usuarioAuth)
        setUser(usuarioAuth)
        showToast('success','Seja Bem vindo!')
      }


   
    }
  }

  function alterType() {
    setExibeSenha(!exibeSenha)
  }

    const header = (label) => { 
      return(
        <div className="font-bold mb-3">{label}</div>
      ) 
    }

    const footer = (
      <div className="footer-password-info">
            <Divider  />
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li>1. Contém pelo menos uma letra maiúscula (A-Z)</li>
                <li>2. Contém pelo menos uma letra minúscula (a-z)</li>
                <li>3. Contém pelo menos um caractere numérico (0-9)</li>
                <li>{"4. Contém pelo menos um caractere especial '(!@#$%^&*()_+[]{};:|,.<>?)"}</li>
                <li>5. Tem pelo menos 8 caracteres</li>
            </ul>
        </div>
    );


  return (
    <div className="main-container-login"> 
        <div className='container-login'>  
          <div className='container-login-footer'>
            <img src={logo} alt="logo-sistema" />
          </div>      
          <form className='form-login' onSubmit={handledSubmit}>
              <span className='form-login-style-title'>
                Acesso ao sistema
                <hr id='linha-signin' />
              </span>
              
              
              <div className='form-login-inputs'>
                <InputText placeholder='email@site.com.br' id='form-login-email' name='email' value={form.email} onChange={handleInputChange}/>                 
                
                <div className="wrap-password">
                  <input className="input200 has-val" placeholder='Senha' type={!exibeSenha ? "password" : "Text"} name="password" value={form.password} onChange={handleInputChange} />

                  {!exibeSenha && <VisibilityIcon className="iconPassword" onClick={alterType} />}

                  {exibeSenha && <VisibilityOffIcon className="iconPassword" onClick={alterType} />}
                </div>

                {isPrimeiroAcesso &&
                  <div className='primeiro-acesso'>
                    
                    <div className="info-nova-senha">
                      <p>Olá, identificamos que esse é o seu primeiro acesso.</p>
                      <p>Então vamos lá, informe uma nova senha abaixo.</p>                    
                    </div>
                    
                    <Password placeholder="Nova Senha" name='novaSenha' value={form.novaSenha} onChange={handleInputChange} header={header('Nova senha')} footer={footer} />
                    <Password placeholder='Confirmar nova senha' name='confirmarNovaSenha' value={form.confirmarNovaSenha} onChange={handleInputChange} header={header('Confirmar nova senha')} footer={footer} />
                    
                    <div className="flex align-items-center">
                        <Checkbox inputId="aceiteLegalInsights" name="aceiteLegalInsights" value={form.aceiteLegalInsights} onChange={handleInputChange} checked={form.aceiteLegalInsights} />
                        <label htmlFor="aceiteLegalInsights" className="ml-2">Eu li e concordo com os termos de uso e política de privacidade da Legal Insights.</label>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="aceiteContatoWhatsApp" name="aceiteContatoWhatsApp" value={form.aceiteContatoWhatsApp}  onChange={handleInputChange}  checked={form.aceiteContatoWhatsApp}/>
                        <label htmlFor="aceiteContatoWhatsApp" className="ml-2">Aceito contato da Legal Inisghts através do celular ou  WhatsApp.</label>
                    </div>

                    <div className='form-login-entrar-primeiro-acesso'>
                      <Button label='Acessar' type={'submit'} />
                    </div>
                  </div>
                }
              </div>
              
              {!isPrimeiroAcesso && 
                <>
                  <div className='form-login-entrar'>
                    <Button label='Login' type={'submit'} />
                  </div>

                  <div className='form-login-esqueci-senha'>                                
                    <div>
                      <Link to={'/esqueceuSenha'}>Esqueceu a senha?</Link>
                    </div>              
                  </div>
                </>              
              }
          </form>
        </div>
        <div className='login-footer-icon-whatsapp'>
          <div>
            <FaWhatsapp size={50} color='#25d366' onClick={()=> {
               return window.open('http://wa.me/5511915576213','_blank')
            }}/>
          </div>          
        </div>        
    </div>
  );
}

export default SignIn;
