import React, { useContext } from 'react';
import './style.css';

import { AuthContext } from '../../../contexts/LegalInsights/auth'
import { buscarIcon } from '../../../utils/Compartilhados';
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext';
import { useNavigate } from 'react-router-dom';
import ShowModalConfiguracoes from '../ShowModal';


const Header = () => {

    const { user } = useContext(AuthContext);
    const { 
            showModalConfiguracoes, setShowModalConfiguracoes, moduloSelecionado
        } = useContext(LegalInsightsCrudContext) 
    const navegate = useNavigate()

    const onClickMShowMenuConfiguracoes = () => {
        setShowModalConfiguracoes(!showModalConfiguracoes)
    }
    
    return (      
        <div className='header-main-container'>
            <div className="header-left">
                <div>
                    <img src={buscarIcon('LogoLegalInsights')} alt="" onClick={()=>navegate('/modulos')}/>
                    <span>{moduloSelecionado}</span>
                </div>                
            </div>
            <div className="header-right">
                <div className="header-icon-sino"><img src={buscarIcon('Icon1')} alt="" /></div>
                <div className="header-perfil">Olá, {user?.nome}</div>
                <div className="header-icon-configuracoes"><img src={buscarIcon('Icon3')} alt=""  onClick={onClickMShowMenuConfiguracoes}/></div>
            </div>

            {showModalConfiguracoes? <ShowModalConfiguracoes /> : <> </>}
        </div>        
    )
}

export default Header;