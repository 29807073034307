import React from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export const ActionTextField = ({ name, label, onChange }) => {
    return(
        <Box
            component="form"
            sx={{
                '& > :not(style)': { 
                    m: 1, 
                    width: '250px', 
                    borderRadius: '50px', 
                    height: '45px',
                },
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
            noValidate
            autoComplete="off"
        >
            <TextField                  
                label={label}  
                name={name} 
                variant="outlined" 
                onChange={onChange}
                type="text"
                sx={{
                    '& label.MuiInputLabel-root': {
                        marginTop: '-8px'
                    },
                    '& .MuiInputBase-root':{
                        '&:hover': {
                            cursor:'pointer'
                        },
                        alignItems: 'center',
                    },
                    '& .MuiOutlinedInput-notchedOutline': { 
                        border: 'none',                         
                    },
                    border: '0.15rem solid var(--cor1)',
                    height: '45px',
                    borderRadius: '30px',
                    display: 'flex',
                    alignItems:'center',
                    '&:hover':{
                        border: '0.19rem solid var(--cor1)',                        
                    }
                }}
            />
        </Box>
    );
};

ActionTextField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
};
