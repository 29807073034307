import React, { useContext, useEffect } from 'react'
import './index.css'
import CardEtapas from '../../Components/LegalInsights/CardEtapas'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'
import { InputText } from 'primereact/inputtext'
import useForm from '../../hooks/useForm'
import IMGPessoaJuridica from '../../assets/Imagens/2.png'
import { InputMask } from 'primereact/inputmask'
import { Checkbox } from 'primereact/checkbox'
import { ButtonLabelComponent } from '../../Components/Commons/Button'
import { showToast } from '../../utils/Compartilhados/CustomToast'
import { isStrongPassword } from '../../utils/Compartilhados/Validacoes'
import { tipoUsuario } from '../../utils/Compartilhados/Enun'
import { useNavigate } from 'react-router-dom'

const CadastroPessoaFisica = () => {    
    const [form, handleInputChange] = useForm({ 
        cnpj: '', 
        nomeCompleto: '', 
        cpf: '', 
        celular: '', 
        email: '', 
        aceiteLegalInsights: false, 
        aceiteContatoWhatsApp: false,
        codigo: '',
        senha: '',
        novaSenha: ''
    }) 

    const { 
            stepEtapa, 
            setStepEtapa, 
            enviarCodigoSeguranca,
            validarCodigoSeguranca,
            createUserLegalInsights
    } = useContext(LegalInsightsCrudContext)
    const headerTitulo = 'CADASTRO DE PESSOA JURÍDICA'
    const navegate = useNavigate()

    useEffect(()=>{
        setStepEtapa(1)
    },[])

    const onClickEtapa = async (step) => {
        
        if(step === 2){
            if(form.cnpj === '' || form.cnpj === undefined) return showToast('warning','CNPJ não informado!')
            setStepEtapa(step)
            return
        } 

        if(step === 3){
            if(form.nomeCompleto === '') return showToast('warning','Nome Completo não informado!')
            if(form.cpf == '') return showToast('warning','CPF não informado!')
            if(form.celular === '' ) return showToast('warning','Celular não informado!')
            if(form.email === '') return showToast('warning','E-Mail não informado!')
            if(form.aceiteLegalInsights === false) return showToast('warning','Aceite do termo de uso e política de privacidade da Legal Insigths é obrigatório!')

            await enviarCodigoSeguranca(form.email, 'juridico')
            setStepEtapa(step)
            return
        }  
        
        if(step === 4){
            if(form.codigo === '' || form.codigo === undefined) return showToast('warning','Código não informado!')
            const data = await validarCodigoSeguranca(form.email, Number(form.codigo))
            if(data.statusCode === 400) return
            setStepEtapa(step)
        }

        if(step === 5){
            if(form.senha === '' || form.senha === undefined) return showToast('warning','Senha não informada!') 
            if(form.novaSenha === '' || form.novaSenha === undefined) return showToast('warning','Nova Senha não informada!')
            if(form.senha != form.novaSenha) return showToast('warning','Senha não confere!')

            if(!isStrongPassword(form.senha)){
                return showToast('warning',<div className='toat-warning-senha'>
                   <span>1. Contém pelo menos uma letra maiúscula (A-Z)</span> <hr></hr>
                   <span>2. Contém pelo menos uma letra minúscula (a-z)</span> <hr></hr>
                   <span>3. Contém pelo menos um caractere numérico (0-9)</span> <hr></hr>
                   <span>{"4. Contém pelo menos um caractere especial '(!@#$%^&*()_+[]{};:|,.<>?)"}</span> <hr></hr>
                   <span>5. Tem pelo menos 8 caracteres</span> 
                </div>)
            }

            if(isStrongPassword){
                const payload = {
                    cnpj: form.cnpj,
                    name: form.nomeCompleto,
                    cpf: form.cpf,
                    celular: form.celular,
                    email: form.email,
                    aceiteTermoPoliticaLegalInsights: form.aceiteLegalInsights,
                    aceiteContatoLegalInsightsCelularOuWhatsApp: form.aceiteContatoWhatsApp,
                    password: form.senha,
                    userType: tipoUsuario.PESSOA_JURIDICA
                }
                const data = await createUserLegalInsights(payload)
                if(data.statusCode === 400) return

                if(data.statusCode !== 400 ){
                    navegate('/')
                }
            }
        }

        if(step === 6){
            return await enviarCodigoSeguranca(form.email, 'juridico')
        }
    }

    const onClickIconStep = async (e) => {

        const step2Validacao = ['step3','step4']
        const step3Validacao = ['step4']


        if(step2Validacao.includes(e.currentTarget.id)){
            if(form.nomeCompleto === '') return showToast('warning','Nome Completo não informado!')
            if(form.cpf === '') return showToast('warning','CPF não informado!')
            if(form.celular === '' ) return showToast('warning','Celular não informado!')
            if(form.email === '') return showToast('warning','E-Mail não informado!')
            if(form.aceiteLegalInsights === false) return showToast('warning','Aceite do termo de uso e política de privacidade da Legal Insigths é obrigatório!')
        }

        if(step3Validacao.includes(e.currentTarget.id)){
            if(form.codigo === '' || form.codigo === undefined) return showToast('warning','Código não informado!')
            return showToast('warning','Clique no botão "Próximo"!')

        }

        switch(e.currentTarget.id){
            case 'step1':
                setStepEtapa(1)
                break
            case 'step2':
                setStepEtapa(2)
                break
            case 'step3':
                setStepEtapa(3)
                break
            case 'step4':
                setStepEtapa(4)
                break   
            default:     
                setStepEtapa(1)
                break                                            
        }
    }

    return(<div className='main-container-cadastro-pessoa-juridica'>
        {stepEtapa === 2 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={4} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGPessoaJuridica}>
                <span className='pessoa-juridica-header'><b>Responsável pela empresa!</b></span>

                <div className='pessoa-juridica-conteudo'>
                    <InputText placeholder='Nome Completo' name='nomeCompleto' value={form.nomeCompleto} onChange={handleInputChange}/>
                    <div>
                        <InputMask className='conteudo-cpf' placeholder='CPF' name='cpf' value={form.cpf} mask='999.999.999-99' onChange={handleInputChange}/>
                        <InputMask className='conteudo-celular' placeholder='Celular' name='celular' value={form.celular} mask='(99) 9 9999-9999'  onChange={handleInputChange}/>
                    </div>
                 
                    <InputText placeholder='E-mail' name='email' value={form.email} onChange={handleInputChange} /> 
                    
                    <div className="flex align-items-center">
                        <Checkbox inputId="aceiteLegalInsights" name="aceiteLegalInsights" value={form.aceiteLegalInsights} onChange={handleInputChange} checked={form.aceiteLegalInsights} />
                        <label htmlFor="aceiteLegalInsights" className="ml-2">Eu li e concordo com os termos de uso e política de privacidade da Legal Insights.</label>
                    </div>
                    <div className="flex align-items-center">
                        <Checkbox inputId="aceiteContatoWhatsApp" name="aceiteContatoWhatsApp" value={form.aceiteContatoWhatsApp}  onChange={handleInputChange}  checked={form.aceiteContatoWhatsApp}/>
                        <label htmlFor="aceiteContatoWhatsApp" className="ml-2">Aceito contato da Legal Inisghts através do celular ou  WhatsApp.</label>
                    </div>
                </div>

                <div className='pessoa-juridica-botoes'>
                    <ButtonLabelComponent  position={1} label='Voltar' onClick={()=> onClickEtapa(1)}/>
                    <ButtonLabelComponent  position={2} label='Próximo' onClick={()=> onClickEtapa(3)}/>
                </div>
            </CardEtapas>
        }

        {stepEtapa === 3 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={4} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGPessoaJuridica}>
                <h1 className='pessoa-juridica-header'><b>Confirmação de e-mail</b></h1>
                <span className='pessoa-juridica-subheading' >Digite o código de segurança que foi enviado ao seu e-mail.</span>
                <span className='pessoa-juridica-subheading'>Enviamos para {form.email? form.email : ''}</span>

                <div className='pessoa-juridica-conteudo'>
                    <InputText placeholder='Código' value={form.codigo} onChange={handleInputChange} name='codigo'/>  
                    <span>Informe o código acima</span>                  
                </div>

                <div className='pessoa-juridica-botoes'>
                    <ButtonLabelComponent position={1} label='Reenviar e-mail' onClick={()=> onClickEtapa(6)} />
                    <ButtonLabelComponent position={2} label='Próximo' onClick={()=> onClickEtapa(4)}/>
                </div>
            </CardEtapas>
        }


        {stepEtapa === 4 &&
            <CardEtapas stepEtapa={stepEtapa} titulo={headerTitulo} totalStepEtapas={4} navegateEtapa={(e) => onClickIconStep(e)} imagem={IMGPessoaJuridica}>
                <span  className='pessoa-juridica-header'><b>Cadastro da senha!</b></span>
                
                <div className='info-senha'>
                    <span>A senha deve ser de no mínimo 8 dígitos e conter pelo menos uma letra MAIÚSCULA, </span>
                    <span>uma minúscula, um caráter especial (@!*) e um número.</span>
                </div>
                
                <div className='pessoa-juridica-conteudo'>
                    <InputText type='password' name='senha' value={form.senha} placeholder='Senha' onChange={handleInputChange} />
                    <InputText type='password' name='novaSenha' value={form.novaSenha} placeholder='Nova Senha' onChange={handleInputChange} />                
                </div>

                <div className='pessoa-juridica-botoes'>
                    <ButtonLabelComponent position={2} label='Entrar' onClick={()=> onClickEtapa(5)}/>
                </div>
            </CardEtapas>
        }
        
    </div>)
}

export default CadastroPessoaFisica