import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext'
import './index.css'
import { useNavigate } from 'react-router-dom'


const MenuLatel = ({ menuLateralPorModulos }) => {
    const { menuSelecionado, setMenuSelecionado } = useContext(LegalInsightsCrudContext)

    const navegate = useNavigate()

    const menuSelcionado = (event, router) => {
        const menuItemId = event.target.id
        setMenuSelecionado(menuItemId)
        
        if('id-menu-fale-conosco' === menuItemId){
            return window.open('https://wa.me/message/DBJUOFGOH5IYG1','_blank')
        }
        navegate(`/${router}`)
    }

    
    return(<div className='menu-lateral-container'>        
        {menuLateralPorModulos.map( (item, index) => {
            return(
            <ul key={index}>
                <div>
                    <li 
                        className={menuSelecionado === item.id? 'menuSelecionado' : ''} 
                        id={item.id} 
                        key={index}  
                        onClick={(event)=> menuSelcionado(event, item.routers)} >
                        <img src={item.icon} alt=""/>
                        {item.labelMenuOpcao}                         
                    </li>
                </div>
            </ul>)
        })}

        <div className='menu-lateral-footer'>
            <span>Uma empresa @Pactbr <br></br>
                Tel. +55 11 3197.6760<br></br>
                © Pact. Todos os direitos reservados
            </span>
        </div>
    </div>)
}

MenuLatel.propTypes = {
    menuLateralPorModulos: PropTypes.array.isRequired,
}

export default MenuLatel