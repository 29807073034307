import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonLabelComponent } from '../../Commons/Button'
import './index.css'

const CustomCardModulos = ({ iconLeft, iconRight, titulo, btnLabel, onClick, btnDisable, index, ordenacaoExibicaoModulo }) => {
    const [flipper ]= useState(false)


    return(
                            
        <div className="custom-modulo-card-main-container" key={index} id={index}>
            <div className='custom-modulo-container'>
                <div className={`custom-modulo-container-icon${!flipper?'-back':''}`}>                         
                    {iconRight === undefined || iconRight === ''?  <></> : <div></div> }   
                    {iconLeft === undefined || iconLeft === ''? <></> : <img src={iconLeft} alt="" /> }                                                             

                </div>

                <div className={`custom-modulo-container-titulo-${btnDisable? 'bloqueado' : 'liberado' }`}>
                    <h1>{titulo}</h1>
                </div>

                <div className={`custom-modulo-container-botao-${btnDisable? 'bloqueado' : 'liberado' }`}>
                    <ButtonLabelComponent id={ordenacaoExibicaoModulo}  position={2} onClick={onClick} label={btnLabel}  />
                </div> 
            </div>
        </div>
    )
}

CustomCardModulos.propTypes = {
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    titulo: PropTypes.string,
    btnLabel: PropTypes.string,
    onClick: PropTypes.func,
    btnDisable: PropTypes.bool,
    index: PropTypes.number,
    ordenacaoExibicaoModulo: PropTypes.number,
}

export default CustomCardModulos