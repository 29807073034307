import React, { useContext, useEffect } from 'react';
import OpcaoFechar  from  '../../assets/Icons/25.png'

import './index.css';
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext';
import { useNavigate } from 'react-router-dom';


const MeuPerfil = () =>{
    const { menuSelecionado, setMenuSelecionado, showMeuPerfil, setShowMeuPerfil } = useContext(LegalInsightsCrudContext)
    
    useEffect(()=>{setMenuSelecionado('')},[])
    const navegate = useNavigate()
    
    const menuSelcionado = (event) => {
        const {id, title } = event.target
        setMenuSelecionado(id)
        
        switch(title){
            case 'alterarSenha':
                return navegate('/projetoAcordo/alterarSenha')
            default:
                return navegate('/projetoAcordo/statusReport')

        }
    }

    return(<div className="meu-perfil-Container">
        <div className="meu-perfil-close"><img src={OpcaoFechar} alt="icon-fechar" onClick={()=> setShowMeuPerfil(!showMeuPerfil)}/></div>
        {/* <div className="meu-perfil-avatar"><img src={Avatar} alt="icon-avatar-usuario" /></div> */}
        <div className="meu-perfil-avatar"></div>
        <div className="meu-perfil-menu">
            <ul>
                <li 
                    id={`meu-perfil-menu-alterar-senha`} 
                    onClick={menuSelcionado}
                    className={menuSelecionado === 'meu-perfil-menu-alterar-senha'? 'menuSelecionado' : ''}
                    title='alterarSenha'
                >
                    Alterar senha
                </li>
            </ul>
        </div>
    </div>)
}

export default MeuPerfil;