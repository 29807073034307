import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import './index.css'

import { BiCloudUpload } from 'react-icons/bi'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext';


const FileUpload = ({ textoDoUpload }) => {
  const [file, setFile] = useState(null);
  const { setArquivoUpload } = useContext(LegalInsightsCrudContext)

  const handleSelectFile = async (e) => {
    const file = e.target.files[0];
    setArquivoUpload(e)
    setFile(file);
  }

  return (
    <div id='file-upload'>
      <label htmlFor="file-input" id='file-uploadLabel'>
        {file ? (
          <div>
            <span>Arquivo carregado:</span>
            <p>{file.name}</p>
          </div>
        ) : (
        <>
          {<BiCloudUpload size={28}/>}
            <p id='file-uploadLabel'>            
              {textoDoUpload}
            </p>
          </>          
        )}
      </label>
      <input className='file-uploadLabel'
        id="file-input"
        type="file"
        onChange={handleSelectFile}
        style={{ display: 'none' }}
      />
    </div>
  );
}

FileUpload.propTypes = {
    textoDoUpload: PropTypes.any
}

export default FileUpload