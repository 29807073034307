
import React from "react";
import PropTypes from 'prop-types'
import { Password } from "primereact/password"
import { Divider } from "primereact/divider";
import './index.css'



const footer = (
    <div className="footer-password-info">
        <Divider  />
        <ul className="pl-2 ml-2 mt-0 line-height-3">
            <li>1. Contém pelo menos uma letra maiúscula (A-Z)</li>
            <li>2. Contém pelo menos uma letra minúscula (a-z)</li>
            <li>3. Contém pelo menos um caractere numérico (0-9)</li>
            <li>{"4. Contém pelo menos um caractere especial '(!@#$%^&*()_+[]{};:|,.<>?)"}</li>
            <li>5. Tem pelo menos 8 caracteres</li>
        </ul>
    </div>
);

export const TextFieldPasswordComponent = ({ headerLabel, name, onChange, value, showFooterPassword, promptLabel }) =>{

    return(
        <Password 
            placeholder={headerLabel}
            className={`textfiled-password-component`} 
            value={value}            
            name={name}
            onChange={onChange}     
            feedback={showFooterPassword? true : false}    
            footer={showFooterPassword? footer : <></>}   
            promptLabel={promptLabel}
            weakLabel="Fraca"
            mediumLabel="Média"
            strongLabel="Forte"
        />
    )
}

TextFieldPasswordComponent.propTypes = {
    headerLabel: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    showFooterPassword: PropTypes.bool,
    promptLabel: PropTypes.string
}