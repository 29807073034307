import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'
import { FormControl, InputLabel } from '@mui/material'

export const ActionSelect = ({ label, data, onChange, name }) => {

    return(
    <React.Fragment>
        <FormControl sx={{ m: 1 }} fullWidth>
            <InputLabel id="action-input-label" sx={{
                '&.Mui-focused': {
                    border: 'none',
                },
                marginLeft: '1rem',
                paddingTop: '0.2rem'

            }}>
                {label}
            </InputLabel>
            <Select 
                className='action-select'
                id='action-select'
                defaultValue=""
                label={label}
                onChange={onChange}
                name={name}
                sx={{
                    borderRadius: '50px',
                    width: '250px',
                    height: '45px',
                    margin: '0.5rem',
                    border: '0.15rem solid var(--cor1)',   
                    '&.MuiSelect-select': { 
                        border: 'none', 
                    },
                    '&.MuiOutlinedInput-notchedOutline': { 
                        border: 'none', 
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '&:hover':{
                        '.MuiOutlinedInput-notchedOutline': {
                            border: '1.5px solid var(--cor1)',
                        },
                        border: '0.15rem solid var(--cor1)',   
                    },
                }}
            >
                {data?.map((item, index) => {
                    return <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                })}            
            </Select>
        </FormControl>
    </React.Fragment>    
    )
}

ActionSelect.propTypes ={
    data: PropTypes.array,
    label: PropTypes.string,
    onChange: PropTypes.onChange,
    name: PropTypes.string
}