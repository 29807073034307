import React, { useState } from "react"
import { toast } from "react-toastify"
import './styled.css'
import 'react-toastify/dist/ReactToastify.css'
import PropTypes from 'prop-types'


export const showToast = (type, messagem) => {    
    switch(type){
        case 'success':
            toast.success(messagem,{
                progressClassName: 'custom-toast-progress-success', 
                className: 'custom-toast-success'
            })
            break
        
        case 'error':
            toast.error(messagem,{
                progressClassName: 'custom-toast-progress-error', 
                className: 'custom-toast-error'
            })
            break

        case 'info':
            toast.info(messagem,{
                progressClassName: 'custom-toast-progress-info', 
                className: 'custom-toast-info'
            });
            break
            
        case 'warning':
            toast.warning(messagem,{
                progressClassName: 'custom-toast-progress-warning', 
                className: 'custom-toast-warning'
            })
            break
            
        default:
            toast(messagem,{
                progressClassName: 'custom-toast-progress-default', 
                className: 'custom-toast-default'
            })
            break
    }
}

const styleConfirmDeleteToast = {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    backgroundColor: '#984de2', 
    color: 'white', 
    height: "30px", 
    width: "80px", 
    borderRadius: "25px", 
    border: "none", 
    transition: "background-color 0.3s ease"
}

const styleCancelDeleteToast = {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center", 
    backgroundColor: '#5499e9', 
    color: 'white', 
    height: "30px", 
    width: "80px", 
    borderRadius: "25px", 
    border: "none", 
    transition: "background-color 0.3s ease" 
}

export const ConfirmDeleteToast = ({ onConfirm, onCancel }) => {
    const [confirmStyle, setConfirmStyle] = useState(styleConfirmDeleteToast);
    const [cancelStyle, setCancelStyle] = useState(styleCancelDeleteToast);

    return (
        <div>
            <p style={{ marginBottom: '1rem'}}>Tem certeza que deseja deletar o arquivo?</p>
            <div style={{ display: 'flex', justifyContent: 'space-around'}}>
                <button
                    onClick={onConfirm}
                    style={confirmStyle}
                    onMouseEnter={() => setConfirmStyle({ ...confirmStyle, backgroundColor: '#7a39c0' })}
                    onMouseLeave={() => setConfirmStyle(styleConfirmDeleteToast)}
                >
                    Sim
                </button>
                <button
                    onClick={onCancel}
                    style={cancelStyle}
                    onMouseEnter={() => setCancelStyle({ ...cancelStyle, backgroundColor: '#407bbd' })}
                    onMouseLeave={() => setCancelStyle(styleCancelDeleteToast)}
                >
                    Não
                </button>
            </div>
        </div>
    )
}

ConfirmDeleteToast.propTypes = {
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
}