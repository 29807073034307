import React, { useContext } from 'react'
import './index.css'

import { Dialog } from 'primereact/dialog'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext'
import ShowModalConfiguracoesItens from '../ShowModalConfiguracoesItens'

const ShowModalConfiguracoes = () => {
    const {showModalConfiguracoes, setShowModalConfiguracoes } = useContext(LegalInsightsCrudContext)

    const hanbleDialog =() => {
        setShowModalConfiguracoes(!showModalConfiguracoes)
    }  

    return(<>
    {showModalConfiguracoes && 
        <Dialog
            visible={showModalConfiguracoes}
            style={{ width: '450px' }}
            header="Configurações"
            modal
            className='show-modal-configuracoes'
            onHide={hanbleDialog}
        >
            <ShowModalConfiguracoesItens />
        </Dialog>
    }
    </>)
}

export default ShowModalConfiguracoes