import React, { useContext} from 'react'
import { LegalInsightsCrudContext } from '../../contexts/LegalInsights/LegalInsightsCrudContext'
import Header from '../../Components/Commons/Header'
import MenuLatel from '../../Components/Commons/MenuLateral'
import MenuConfiguracoes from '../../Components/Commons/MenuConfiguracoes'
import MeuPerfil from '../MeuPerfil'
import './index.css'
import { menuLateralOpcoesPorModulos } from '../../utils/Compartilhados'
import { ButtonLabelComponent } from '../../Components/Commons/Button'
import { TextFieldPasswordComponent } from '../../Components/Commons/TextField'
import useForm from '../../hooks/useForm'
import { showToast } from '../../utils/Compartilhados/CustomToast'
import { isStrongPassword } from '../../utils/Compartilhados/Validacoes'
import { AuthContext } from '../../contexts/LegalInsights/auth'





const AlterarSenha = () => {
    const { showMenuConfiguracoes, showMeuPerfil } = useContext(LegalInsightsCrudContext)
    const { updatePassword, user } = useContext(AuthContext)
    const [form, handleInputChange, clear] = useForm({ senhaAtual: '', novaSenha: '', confirmarSenha: '' })

    const onClick = async () => {
        if(form.senhaAtual === '' || form.senhaAtual === undefined) return showToast('warning', 'Senha atual não informada!')
        if(form.novaSenha === '' || form.novaSenha === undefined) return showToast('warning', 'Nova senha não informada!')
        if(form.confirmarSenha === '' || form.confirmarSenha === undefined) return showToast('warning','Confirmar senhão não informada!')
        if(form.novaSenha != form.confirmarSenha) return showToast('warning','Senha não confere!')

        if(!isStrongPassword(form.novaSenha)) return showToast('warning','A senha fornecida não atende aos requisitos mínimos de segurança.')

        if(isStrongPassword(form.novaSenha)){
            const response = await updatePassword(user.email, form.senhaAtual, form.novaSenha, form.confirmarSenha) 
            if(response.status === 200){
                clear()
            }    
        }
    }

    return(
        <div className="modulo-projeto-acordos-container">
            <Header />   

            <div className="projeto-acordo-container">
                <div className="projeto-acordo-menu-left">
                    <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos('projetoAcordo')}/>
                </div>

                <div className="projeto-acordo-conteudo">
                    <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >    
                        <div className="container-alterar-senha">
                            <div className="titulo-alterar-senha">
                                <h2>Alterar senha</h2>
                            </div>
                            <TextFieldPasswordComponent headerLabel={'Senha atual'} onChange={handleInputChange} name={'senhaAtual'} value={form.senhaAtual} />
                            <TextFieldPasswordComponent headerLabel={'Nova senha'} onChange={handleInputChange} name={'novaSenha'} value={form.novaSenha} showFooterPassword={true} promptLabel={' '} />
                            <TextFieldPasswordComponent headerLabel={'Confirmar senha'} onChange={handleInputChange} name={'confirmarSenha'} value={form.confirmarSenha} showFooterPassword={true} promptLabel={' '} />
                            <div className='alterar-senha-salvar'>
                                <ButtonLabelComponent label={'Salvar'} onClick={onClick} />
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="projeto-acordo-menu-right">
                    {showMenuConfiguracoes && <MenuConfiguracoes /> }
                    {showMeuPerfil && <MeuPerfil /> }              
                </div>       
            </div>  
        </div>
    )
}

export default AlterarSenha
