import React from 'react';
import PropTypes from 'prop-types'
import { Dialog } from 'primereact/dialog';
import { ButtonLabelComponent } from '../Button';

import './index.css'

export const DialogAprovacoes = ({ visibleDialog, onClickConfirmar, onclicCancelar, mensagem }) => {
    return( <Dialog
        visible={visibleDialog}
        style={{ width: "500px" }}
        modal
        className="card p-fluid"
        closable={false}
        id={'dialog_aprovacoes'}
        
    >
        <span className='aprovacoes-text'>{mensagem}</span>

        <div className="field button-right">            
            <ButtonLabelComponent label={'Cancelar'} onClick={onclicCancelar} type={'cancelar'}/>
            <ButtonLabelComponent label={'Confirmar'} onClick={onClickConfirmar}/>
        </div>

    </Dialog>)
}

DialogAprovacoes.propTypes = {
    visibleDialog: PropTypes.bool,
    onClickConfirmar: PropTypes.func,
    onclicCancelar: PropTypes.func,
    mensagem: PropTypes.string
}


export const DialogPadrao = ({ visibleDialog, mensagem, hide }) => {
    return( <Dialog
        visible={visibleDialog}
        style={{ width: "500px" }}
        modal
        className="card p-fluid"
        id={'dialog_aprovacoes'}
        onHide={hide}
        
    >
        <span className='detalhe'>{mensagem}</span>        

    </Dialog>)
}

DialogPadrao.propTypes = {
    visibleDialog: PropTypes.bool,
    mensagem: PropTypes.string,
    hide:  PropTypes.bool
}


export const DialogDownload = ({ visibleDialog, mensagem, hide, colunas, onclicCancelar }) => {
    return( <Dialog
        visible={visibleDialog}
        style={{ width: "500px" }}
        modal
        className="card p-fluid"
        id={'dialog_aprovacoes'}
        onHide={hide}
        closable={false}
    >

        <span className='detalhe-download'>{mensagem}</span>  
        
        <div className='documentos-download'>
            <ul>{colunas?.map( (item,index) => 
                <li key={index}>
                    <a href={item.link} target='_blank' download={true} rel='noreferrer' >{item.nomeDocumento}</a> 
                </li>)}
            </ul>
        </div>        

        <div className="field button-right">            
            <ButtonLabelComponent label={'Cancelar'} onClick={onclicCancelar} type={'cancelar'}/>            
        </div>

    </Dialog>)
}


DialogDownload.propTypes = {
    visibleDialog: PropTypes.bool,
    mensagem: PropTypes.string,
    hide: PropTypes.bool,
    colunas: PropTypes.array,
    onclicCancelar: PropTypes.func
}


export const DialogObservacoes = ({ visibleDialog, onClickConfirmar, onclicCancelar, mensagem, handleInputChange, observacoes, nomeCampoTextArea }) => {
    return( <Dialog
        visible={visibleDialog}
        style={{ width: "500px" }}
        modal
        className="card p-fluid"
        closable={false}
        id={'dialog_aprovacoes'}
        
    >
        <span className='aprovacoes-text'>{mensagem}</span>        
        <textarea className='aprovacoes-text-area' placeholder='Observações' name={nomeCampoTextArea} id="" cols="58" rows="10" onChange={handleInputChange}>{observacoes}</textarea>

        <div className="field button-right">            
            <ButtonLabelComponent label={'Cancelar'} onClick={onclicCancelar} type={'cancelar'}/>
            <ButtonLabelComponent label={'Confirmar'} onClick={onClickConfirmar}/>
        </div>

    </Dialog>)
}

DialogObservacoes.propTypes = {
    visibleDialog: PropTypes.bool,
    onClickConfirmar: PropTypes.func,
    onclicCancelar: PropTypes.func,
    mensagem: PropTypes.string,
    handleInputChange: PropTypes.func,
    observacoes: PropTypes.string,
    nomeCampoTextArea: PropTypes.string
}