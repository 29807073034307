import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import axios from 'axios';
import { AuthContext } from './LegalInsights/auth'

export const ImportacaoContext = createContext({})

function ImportacaoProvider({ children }) {
    const { user } = useContext(AuthContext);
    const [modeloUm, setModeloUm] = useState([])
    const [arquivos, setArquivos] = useState([])
    const [gridHistorico, setGridHistorico] = useState([]);
    const [totalArquivosParaDeletar, setTotalArquivosParaDeletar] = useState(0)

    const [newImport, setNewImport] = useState(false);

    const [isEmpty, setIsEmpty] = useState(null);
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);


    const urlBase = process.env.REACT_APP_PACT_API;

    async function getImportacao() {
        setModeloUm([])
        setLoad(true)
        setLoad2(true)        
        
        if(user?.tipoUsuario === 'Cliente'){
            await axios.post(urlBase + '/process/buscarProcessoPorProjeto',{ grupoProjetos: user?.grupo },
            {
                headers: {
                    'Authorization': `token ${user.token}`,
                    'Content-Type': 'application/json'
                },
            })
            .then(function (response) {
                updateState(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            }).finally(() => {
                setLoad(false)
                setLoad2(false)
            })
        }


        if(user?.tipoUsuario === 'Administrador Global'){
            await axios.get(urlBase + '/process/all', {
                headers: {
                    'Authorization': `token ${user.token}`
                }
            })
            .then(function (response) {
                updateState(response.data)
            })
            .catch(err => {
                console.log(err)
                toast.error('Algo deu errado')
            }).finally(() => {
                setLoad(false)
                setLoad2(false)
            })                  
        }

        

    }

    async function updateState(snapshot) {
        const isCollectionEmpty = snapshot.length === 0;

        if (!isCollectionEmpty) {
            let lista = [];
            snapshot.forEach((doc) => {
                if (doc.id != undefined && doc.id != "" && doc.id != null) {
                        lista.push({
                            id: doc.id,
                            NCNJ: doc.NCNJ,
                            contrarioPrincipalNomeRazao: doc.contrarioPrincipalNomeRazao,
                            uf: doc.uf,
                            cidade: doc.cidade,
                            advogadoReverso_oab: doc.advogadoAdverso,
                            advogado: doc.advgadoReservo,
                            oab: doc.oab,
                            vara: doc.vara,
                            nVara: doc.nVara,
                            dtDistribuicao: doc.dtDistribuicao,
                            ecBase: doc.ecBase,
                            riscoDCliente: doc.riscoDCliente,
                            faixaRisco: doc.faixaRisco,
                            valorCausa: doc.valorCausa,
                            tipoProcesso: doc.tipoProcesso,
                            tipoAcao: doc.tipoAcao,
                            primeiraReclamada: doc.primeiraReclamada,
                            demaisReclamadas: doc.demaisReclamadas,
                            status: doc.status,
                            fase: doc.fase,
                            momento: doc.momento,
                            dtMomento: doc.dtMomento,
                            dtAudiencia: doc.dtAudiencia,
                            dtEncerramento: doc.dtEncerramento,
                            motivoEncerramento: doc.motivoEncerramento,
                            ticketMedio: doc.ticketMedio,
                            valorAcordoCondenacao: doc.valorAcordoCondenacao,
                            formaPagamentoAcordo: doc.formaPagamentoAcordo,
                            quemPagouAcordo: doc.quemPagouAcordo,
                            momentoEmQueAcordoRealizado: doc.momentoEmQueAcordoRealizado,
                            pedidosDaInicial: doc.pedidosDaInicial,
                            funcaoCompilada: doc.funcaoCompilada,
                            proprioTerceiro: doc.proprioTerceiro,
                            ultimoSalario: doc.ultimoSalario,
                            dtAdmissao: doc.dtAdmissao,
                            dtDemissao: doc.dtDemissao,
                            tempoCasa: doc.tempoCasa,
                            sentenca: doc.sentenca,
                            dtSentenca: doc.dtSentenca,
                            nomeJuiz: doc.nomeJuiz,
                            houveReformaAcordaoRO: doc.houveReformaAcordaoRO,
                            pedidosIncluidosAcordao: doc.pedidosIncluidosAcordao,
                            pedidosExcluidosAcordao: doc.pedidosExcluidosAcordao,
                            dtAcordao: doc.dtAcordao,
                            acordaoReformouImprocedencia: doc.acordaoReformouImprocedencia,
                            rrAdmitido: doc.rrAdmitido,
                            seFoiAdmitidoQualMateria: doc.seFoiAdmitidoQualMateria,
                            houveReformaAcordaoRR: doc.houveReformaAcordaoRR,
                            dtAcordao2: doc.dtAcordao2,
                            juros: doc.juros,
                            hPericiais: doc.hPericiais,
                            honorariosAdvocaticios: doc.honorariosAdvocaticios,
                            custasProcessuais: doc.custasProcessuais,
                            estabilidade: doc.estabilidade,
                            bonus: doc.bonus,
                            equiparacaoSalarial: doc.equiparacaoSalarial,
                            acumuloDeFuncao: doc.acumuloDeFuncao,
                            dMoral: doc.dMoral,
                            dMaterial: doc.dMaterial,
                            pensao: doc.pensao,
                            he: doc.he,
                            horasInItinere: doc.horasInItinere,
                            sebreaviso: doc.sebreaviso,
                            intrajornada: doc.intrajornada,
                            diferencasSalariais: doc.diferencasSalariais,
                            adTransferencia: doc.adTransferencia,
                            insalubridade: doc.insalubridade,
                            periculosidade: doc.periculosidade,
                            ticketMedioporPedido: doc.ticketMedioporPedido,
                            garantia: doc.garantia,
                            possuiGarantia: doc.possuiGarantia,
                            garantiaMaiorouMenorqueRisco: doc.garantiaMaiorouMenorqueRisco,
                            contrarioPrincipalNomeRazao2: doc.contrarioPrincipalNomeRazao2,
                            tipoGarantiaRecursalDepositoApolice: doc.tipoGarantiaRecursalDepositoApolice,
                            valorLiberado: doc.valorLiberado,
                            revelia: doc.revelia,
                            reclamanteFoiOuvidaemAud: doc.reclamanteFoiOuvidaemAud,
                            testemunhaReclamada: doc.testemunhaReclamada,
                            testemunhaReclamante: doc.testemunhaReclamante,
                            dtTransitoemJulgado: doc.dtTransitoemJulgado,
                            valorCalcApresentadoReclamada: doc.valorCalcApresentadoReclamada,
                            valorCalcApresentadoReclamante: doc.valorCalcApresentadoReclamante,
                            valorCalcApresentadoPerito: doc.valorCalcApresentadoPerito,
                            valorHomologado: doc.valorHomologado,
                            dtCalcHomologado: doc.dtCalcHomologado,
                            deQuemfoiCalcHomologado: doc.deQuemfoiCalcHomologado,
                            houveEmbargosExecucao: doc.houveEmbargosExecucao,
                            materiaEe: doc.materiaEe,
                            formaDeGarantia: doc.formaDeGarantia,
                            houvePericia: doc.houvePericia,
                            nomePeritoPericulosidadeInsalubridade: doc.nomePeritoPericulosidadeInsalubridade,
                            nomePeritoAcidenteDoenca: doc.nomePeritoAcidenteDoenca,
                            periciaDeQue: doc.periciaDeQue,
                            resultadoInsalubridade: doc.resultadoInsalubridade,
                            fundamentoInsalubridade: doc.fundamentoInsalubridade,
                            resultadoPericulosidade: doc.resultadoPericulosidade,
                            agentePericulosidade: doc.agentePericulosidade,
                            fundamentoPericulosidade: doc.fundamentoPericulosidade,
                            agenteInsalubre: doc.agenteInsalubre,
                            resultadoDoenca: doc.resultadoDoenca,
                            resultadoAcidente: doc.resultadoAcidente,
                            dtLaudo: doc.dtLaudo,
                            compliadofunHe: doc.compliadofunHe,
                            agenteInsalubre2: doc.agenteInsalubre2,
                            grauDaInsalubridade: doc.grauDaInsalubridade,
                            compiladofunPericulosidade: doc.compiladofunPericulosidade,
                            compiladofunAcidDoenca: doc.compiladofunAcidDoenca,
                            qualDoenca: doc.qualDoenca,
                            incapacidade: doc.incapacidade,
                            reducaoFuncional: doc.reducaoFuncional,
                            compiladoFundDanoMoral: doc.compiladoFundDanoMoral,
                            empresa: doc.empresa,
                            // usuario: doc.usuario,
                            // dtInclusao: doc.dtInclusao,
                            // dtAtualizacao: doc.dtAtualizacao
                            tipo1: doc.tipo1 ? doc.tipo1 : '',
                            tipo2: doc.tipo2 ? doc.tipo2 : '',
                            tipo3: doc.tipo3 ? doc.tipo3 : '',
                            tipo4: doc.tipo4 ? doc.tipo4 : '',
                            tipo5: doc.tipo5 ? doc.tipo5 : '',
                            tipo6: doc.tipo6 ? doc.tipo6 : '',
                            tipo7: doc.tipo7 ? doc.tipo7 : '',
                            tipo8: doc.tipo8 ? doc.tipo8 : '',
                            tipo9: doc.tipo9 ? doc.tipo9 : '',
                            tipo10: doc.tipo10 ? doc.tipo10 : '',
                            baseCliente: doc.baseCliente,
                            cpf_reclamante: doc.cpf_reclamante,
                            projeto: doc.projeto,
                            status_pact: doc.status_pact,
                            cnj_execucao_provisoria: doc.cnj_execucao_provisoria,
                            momento_execucao_provisoria: doc.momento_execucao_provisoria,
                            data_cadastro_processo_base: doc.data_cadastro_processo_base,
                            status_pre: doc.status_pre,
                            data_provisao: doc.data_provisao,
                            provisao: doc.provisao,
                            data_atualizacao_risco: doc.data_atualizacao_risco,
                            risco_bruto: doc.risco_bruto,
                            risco_liquido: doc.risco_liquido,
                            risco_bruto_pact_net_atualizado: doc.risco_bruto_pact_net_atualizado,
                            risco_liquido_pact_net_atualizado: doc.risco_liquido_pact_net_atualizado,
                            depositos_recursais: doc.depositos_recursais,
                            faixa_risco: doc.faixa_risco,
                            gatilho_bruto: doc.gatilho_bruto,
                            status_negociacao: doc.status_negociacao,
                            termometro: doc.termometro,
                            data_primeiro_contato: doc.data_primeiro_contato,
                            data_ultimo_contato: doc.data_ultimo_contato,
                            proposta: doc.proposta,
                            ulitma_pretensao: doc.ulitma_pretensao,
                            data_acordo: doc.data_acordo,
                            data_protocolo: doc.data_protocolo,
                            data_homologacao: doc.data_homologacao,
                            acordo_bruto: doc.acordo_bruto,
                            acordo_liquido: doc.acordo_liquido,
                            status_minutas: doc.status_minutas,
                            inss_reclamada_pago: doc.inss_reclamada_pago,
                            inss_reclamante_pago: doc.inss_reclamante_pago,
                            ir: doc.ir,
                            honorarios_periciais: doc.honorarios_periciais,
                            economia: doc.economia,
                            desagio: doc.desagio,
                            honorario_pact: doc.honorario_pact,
                            economia_net: doc.economia_net,
                            desagio_net: doc.desagio_net, 
                            status_atividade: doc.status_atividade,
                            negociar: doc.negociar,
                            tribunal:  doc.tribunal,
                            dataStatusPre:  doc.dataStatusPre,
                            Observacao:  doc.Observacao,
                            descricaoSolicitacaoCopia:  doc.descricaoSolicitacaoCopia,
                            descricaoSolicitacaoCalculo:  doc.descricaoSolicitacaoCalculo,
                            descricaoUltimaAcaoNegociar:  doc.descricaoUltimaAcaoNegociar,
                            statusPreSubTipo:  doc.statusPreSubTipo,
                            fluxoSaneamento:  doc.fluxoSaneamento,
                            restricaoTipo:  doc.restricaoTipo, 
                        })

                    
                }
            })
            setIsEmpty(false)
            setModeloUm(lista);
        } else {
            setIsEmpty(true);
        }
    }   

    return (
        <ImportacaoContext.Provider value={{
            getImportacao,
            modeloUm,
            load,
            newImport,
            setNewImport,
            arquivos,
            gridHistorico,
            setArquivos,
            setGridHistorico,

            totalArquivosParaDeletar,
            setTotalArquivosParaDeletar,           
            isEmpty,
            setLoad,
            load2
        }}>
            {children}
        </ImportacaoContext.Provider>
    )
}

ImportacaoProvider.propTypes = {
    children: PropTypes.node,
}

export default ImportacaoProvider;