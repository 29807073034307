import React, { useContext } from "react"
import Header from "../../../Components/Commons/Header"
import MenuConfiguracoes from "../../../Components/Commons/MenuConfiguracoes"
import MenuLatel from "../../../Components/Commons/MenuLateral"
import { menuLateralOpcoesPorModulos } from "../../../utils/Compartilhados"
import './index.css'
import { LegalInsightsCrudContext } from "../../../contexts/LegalInsights/LegalInsightsCrudContext"
import MeuPerfil from "../../MeuPerfil"

const ModuloProjetoAcordos = ()=>{   
    const { showMenuConfiguracoes, showMeuPerfil, moduloSelecionado } = useContext(LegalInsightsCrudContext) 
    
    return( 
        <div className="modulo-projeto-acordos-container">
                <Header />   

                <div className="projeto-acordo-container">
                    <div className="projeto-acordo-menu-left">
                        <MenuLatel menuLateralPorModulos={menuLateralOpcoesPorModulos(moduloSelecionado)}/>
                    </div>

                    <div className="projeto-acordo-conteudo">
                        <div className={`main-container-conteudo${showMenuConfiguracoes? '-open' : showMeuPerfil? '-open' : '' } }`} >
                            Projeto arcordo
                        </div> 
                    </div>

                    <div className="projeto-acordo-menu-right">
                        {showMenuConfiguracoes && <MenuConfiguracoes /> }
                        {showMeuPerfil && <MeuPerfil /> }              
                    </div>       
                </div>      
        </div>
    )
}

export default ModuloProjetoAcordos