import React from 'react'

import './index.css'

//Icons
import { RiUserLine } from 'react-icons/ri'
import { FiLogOut } from 'react-icons/fi'
import { RxAccessibility } from "react-icons/rx"


import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../../contexts/LegalInsights/auth'
import { LegalInsightsCrudContext } from '../../../contexts/LegalInsights/LegalInsightsCrudContext'




const ShowModalConfiguracoesItens =() =>{
    const { signOut, user } = useContext(AuthContext)
    const { showModalConfiguracoes, setShowModalConfiguracoes } = useContext(LegalInsightsCrudContext)
    

    const closeModal =()=>{
        setShowModalConfiguracoes(!showModalConfiguracoes)
    }

    return(<div className='configuracoesItem'>

        {user.tipoUsuario === "Administrador Global" &&
        <>
            <Link to={'/projetoAcordo/usuario'} className='configuracoes-item' onClick={closeModal} >
                <div className='item'>
                <RiUserLine />
                <span>Cadastro de Usuário</span>
                </div>
                <hr />
            </Link>
            
            <Link to={'/projetoAcordo/controleAcesso'} className='configuracoes-item' onClick={closeModal} >
                <div className='item'>
                <RxAccessibility />
                <span>Controle de acesso</span>
                </div>
                <hr />
            </Link>
        </>
        
        }
        <Link to={''} className='configuracoes-item' onClick={ ()=> signOut()} >
            <div className='item'   >
                <FiLogOut />
                <span>Sair</span>
            </div>
            <hr />
        </Link>
    </div>)
}

export default ShowModalConfiguracoesItens