import { useState } from "react";

const useForm = ( inicialState ) => {
    //Inicializa o state
    const [form, setForm] = useState(inicialState);

    //seta o valor do input
    const handleInputChange = (event) => {
        if(event.target.type === 'checkbox'){
            const { name, checked } = event.target;
            setForm({...form, [name]: checked });
        }

        if(event.target.type != 'checkbox'){
            const {value, name } = event.target;
            setForm({...form, [name]: value });
        }
    }

    const clear =() => {
        setForm(inicialState)
    }

    return [form, handleInputChange, clear]
}

export default useForm;