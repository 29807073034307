import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import moment from 'moment'
import { InputText } from 'primereact/inputtext';

export const extensoesPermitidas =(fileName) =>{
    const exPermitidas = /(.pdf|.xlsx|.xls|.csv|.txt|.msg|.docx|.doc)$/i;

    if(!exPermitidas.exec(fileName)){
        return false //Não permitido
    }else{
        return true //Permitido
    }
}

export const isExcel =(fileName) =>{
    const exPermitidas = /(.xlsx|.xls)$/i;

    if(!exPermitidas.exec(fileName)){
        return false //Não permitido
    }else{
        return true //Permitido
    }
}


export const bodyTemplateLabel =(rowData, fieldName, lista) =>{
    return getLabel(rowData[fieldName], lista)
}

const getLabel =(status, lista)=>{
    let result = "NA"
    lista.forEach((elemento)=>{
        if(elemento.label === status){
            result = status
        }
    })
    return result
}

export const bodyTemplateValue =(rowData, fieldName, lista) =>{
    return getValue(rowData[fieldName], lista)
}

const getValue =(status, lista)=>{
    let result = "NA"
    lista.forEach((elemento)=>{
        if(elemento.value === status){
            result = elemento.label
        }
    })
    return result
}

export const statusEditorLabel = (options, lista) => {
    return (
      <Dropdown value={options.value} options={lista} optionLabel="label" optionValue="label"
        onChange={(e) => options.editorCallback(e.value)} placeholder="Selecione Tipo Usuario"
        itemTemplate={(option) => {
          return <span className={`product-badge status-${option.label}`}>{option.label}</span>
        }} />
    );
}

export const statusEditorValue = (options, lista) => {
    return (
      <Dropdown value={options.value} options={lista} optionLabel="label" optionValue="value"
        onChange={(e) => options.editorCallback(e.value)} placeholder="Selecione Tipo Usuario"
        itemTemplate={(option) => {
          return <span className={`product-badge status-${option.label}`}>{option.label}</span>
        }} />
    );
}

export const readExcelFile = async (e, ref) => {
    const file = e.target.files[0];
    const permitido = isExcel(file.name)
    // let fileName = ''
    if (!permitido) {
        ref.current.value = ""
        toast.error("Arquivo não permitido")
    }

    if (permitido) {
        // fileName = file.name
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonDataE = XLSX.utils.sheet_to_json(worksheet, { blankrows: false });
        return jsonDataE        
    }

    return []
};

export const dateTemplete =(rowData, column)=>{
    return moment(rowData[column]).format('DD/MM/YYYY HH:MM')
}

export const formartarData =(dataString) => {
    return moment(dataString).format('DD/MM/YYYY')
}

export const formartarDataHora =(dataHoraString) => {
    const data = moment(dataHoraString).format('DD/MM/YYYY HH:MM')
    return data
}

export const formatarDataRetornaHora = (dataHoraString) => {
    const data = moment(dataHoraString).format('HH:mm');
    return data;
}

export const diferencaEntreDataFormatada = (date1, date2) => {
    const diffInMilliseconds = Math.abs(date2 - date1)
    const diffInMinutes = diffInMilliseconds / (1000 * 60)

    if (diffInMinutes >= 60) {
        const hours = Math.floor(diffInMinutes / 60)
        const minutes = Math.round(diffInMinutes % 60)

        if (minutes === 0) {
            return `${hours} hora${hours > 1 ? 's' : ''}`
        } else {
            return `${hours} hora${hours > 1 ? 's' : ''} e ${minutes} minuto${minutes > 1 ? 's' : ''}`
        }
    } else {
        return `${Math.round(diffInMinutes)} minuto${diffInMinutes > 1 ? 's' : ''}`
    }
}


export const convertToBase64 = (data) =>{
    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    return base64String
}

export const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}
